import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SignUpHome from './assets/SignUpHomeImage.png';
import Logo from "./assets/Group 1261153494.png";
import './loginform.css';
import APICall from './Utility/APICall';
import { paths } from './Utility/Constants';
import { useNavigate } from 'react-router-dom';
import ContinueBtn from './components/ContinueBtn';

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^[0-9]*$/, 'Mobile number must contain only numbers')
    .length(10, 'Mobile number must be exactly 10 digits')
    .required('Mobile number is required'),
});

const apiUrl = 'https://renown-backend-assignment.el.r.appspot.com/user/validate';

export default function LoginForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setIsError] = useState(false);

  useEffect(() => {
    console.log("Login Screen mounted");
  }, []);

  const handleOnPressContinue = async (values, { setSubmitting }) => {
    const phoneNumber = values.mobileNumber;
    setIsLoading(true);

    const formData = new FormData();
    formData.append('mobile', phoneNumber);

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const result = await response.json();

      if (result.status !== "success") {
        setIsError(true);
        console.log("unsuccessful login: ", result.status);
        return;
      }

      if (result.response.is_user === false) {
        try {
          const signupResult = await APICall('POST', paths.user.signup, { mobile: phoneNumber });

          if (signupResult.status !== "success") {
            return;
          }
        } catch (error) {
          console.error('Error:', error);
          setIsError(true);
          return;
        }
      }
      navigate('/OTP', { state: { number: phoneNumber, isNewUser: !result.response.is_user } });
    } catch (error) {
      console.error('Error:', error);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ mobileNumber: '' }}
      validationSchema={validationSchema}
      onSubmit={handleOnPressContinue}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='flex' style={{ height: '100vh' }}>
            <div className='part1'>
              <div className='part1-img-container'>
                <img src={SignUpHome} height={121} width={260} alt="SignUpHome" />
              </div>
              <div>
                <p className='px-10' style={{
                  fontSize: 40,
                  fontWeight: 700,
                  color: 'white',
                }}><strong>Rent</strong> management made <strong>easy</strong> on <strong>RentPe</strong></p>
              </div>
            </div>

            <div className='pl-24' style={{ flex: .8 }}>
              <div className='logo-signup-login pt-36 pb-12'>
                <img src={Logo} alt="Logo" width={130} height={130} />
              </div>
              <div>
                <p style={{ paddingBottom: 32, fontSize: 24, fontWeight: 600 }}>
                  Login
                </p>
              </div>
              <div className='flex flex-col'>
                <Field
                  as="input"
                  type="text"
                  className='w-44 p-2 border'
                  placeholder='Enter Mobile'
                  name="mobileNumber"
                />
                <ErrorMessage name="mobileNumber" component="div" className="error text-red-500 text-xs pt-2" />
                <p style={{ color: '#1D252D80', paddingBottom: 32, paddingTop: 10 }}>We’ll send you an OTP to confirm your number</p>
              </div>

              <ContinueBtn disabled={isLoading || isSubmitting} type="submit">
                {isLoading ? <span className="spinner"></span> : 'Continue'}
              </ContinueBtn>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
