import React from "react";
import "./ReportDetails.css"; // Ensure this is imported if using a separate CSS file
import CircularInitial from "../../UpcomingRent/CircularInitial"; // Assuming CircularInitial is imported from this path
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";

const ReportDetails = ({ tenantData, onClose, onMarkAsPaidClick }) => {
  const {
    first_name,
    last_name,
    unit_name,
    building_name,
    due_in,
    local_amount,
    due_date,
    mobile,
  } = tenantData;

  console.log("TenantData",tenantData)
  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${mobile}`, "_blank");
  };

  return (
    <div className="report-details-modal">
      <div className="report-details-modal-content">
        <IoCloseCircleOutline size={35} style={{ float: "right", cursor: "pointer" }} onClick={onClose} />

        <h2 className="report-details-modal-header">Report Details</h2>

        <div className="report-details-info">
          <div className="report-details-details flex flex-col items-center justify-center p-4 gap-2">
            <CircularInitial firstName={tenantData.first_name} lastName={tenantData.lastName} />

            <p style={{ fontSize: '18px' }}>
              {first_name} {last_name}
            </p>
            <p>
              {unit_name}, {building_name}
            </p>
            <p>{local_amount}</p>
            <p>
              {due_date}{" "}
              {due_in === "Overdue" && (
                <span style={{ color: "red" }}>
                  Overdue
                </span>
              )}
            </p>
            <div className="contact-info">
              <p className="flex items-center justify-center gap-2">
                {mobile} <IoIosCall size={20} color="blue" /> <FaWhatsapp size={20} color="green" onClick={handleWhatsAppClick} />
              </p>
            </div>
          </div>
        </div>

        <div className="report-details-modal-actions">
          <button
            className="report-details-overdue-button"
            onClick={onMarkAsPaidClick}
            style={{
              border: "1px solid",
              padding: "10px 40px",
              borderRadius: "10px",
              backgroundColor: "#0D1142",
              fontWeight: 700,
              color: "white",
              margin: "5px"
            }}
          >
            Mark as Paid
          </button>
          <button
            className="flex items-center gap-2"
            style={{
              border: "1px solid",
              padding: "10px 40px",
              borderRadius: "10px",
              backgroundColor: "#0D1142",
              fontWeight: 700,
              color: "white",
              margin: "5px"
            }}
            onClick={handleWhatsAppClick}
          >
            <FaWhatsapp size={20} color="lightgreen" />
            Send Reminder
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportDetails;
