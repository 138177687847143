import React, { useEffect, useState } from "react";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import RentIncome from "../RentIncome/RentIncome";
import RentOverviewTable from "./components/RentOverViewTable";
import ReusableComponent from "../UpcomingRent/ReusableComponent";
import Layout from "../Layout";

function Reports({ mobileNumber }) {
  return (
    // <Layout>
      <div
        className=""
      
      >
        <div className="flex justify-between gap-10 p-4 ">
          {/* <div>
            <p>Overview</p>
            <RentIncome />
          </div> */}
        </div>
        <div>
          <p>Recent Rents</p>
          <RentOverviewTable />
        </div>
      </div>
    // </Layout>
  );
}

export default Reports;
