import React, { useState } from 'react';

const ContinueBtn = ({ onClick, disabled, children }) => {
  const [hovered, setHovered] = useState(false);

  const btnStyle = {
    fontWeight: 'bold',
    padding: '8px 16px',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
    backgroundColor: disabled ? 'gray' : hovered ? 'darkblue' : 'blue',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.5 : 1,
    color: 'white',
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={btnStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
    </button>
  );
};

export default ContinueBtn;
