import React, { useEffect, useState } from "react";
import APICall from "../../../Utility/APICall";
import { paths } from "../../../Utility/Constants";
import { useAuth } from "../../../Utility/AuthProvider";
import Paid from "./Paid";
import OverdueStatus from "./OverdueStatus";
import "./rentoverviewtable.css";
import CircularInitial from "../../UpcomingRent/CircularInitial";
import WhatsAppComponent from "./WhatsappComponent";
import PaidModal from "./PaidModal";
import ReportDetails from "./ReportDetails";
import { BsFilterSquare } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import FilterBuildingModal from "../../Properties/FilterBuildingModal";
import UnitModal from "./UnitModal";
import TenantModal from "./TenantModal";

function RentOverviewTable() {
  const { jwt } = useAuth();
  const [rentOverviewData, setRentOverviewData] = useState([]);
  const [rentData, setRentData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const [isMarkAsPaidLoading, setIsMarkAsPaidLoading] = useState(false);
  const [showReportDetailsModal, setShowReportDetailsModal] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [filters, setFilters] = useState({
    overdue: false,
    today: false,
    building: false,
    unit: false,
    tenant: false,
  });
  const [filterCount, setFilterCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  //building variables
  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [selectedBuildingId, setSelectedBuildingId] = useState(null);
  const [buildings, setBuildings] = useState([]);

  // unit variables
  const [unitData, setUnitData] = useState([]);
  const [showUnitModal, setShowUnitModal] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [units, setUnits] = useState([]); // Fetch or set your units data here
  const [selectedUnit, setSelectedUnit] = useState(null);

  //tenant variables
  const [showTenantModal, setShowTenantModal] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantData, setTenantData] = useState([]);

  const fetchRentOverviewData = async () => {
    try {
      const response = await APICall(
        "GET",
        paths.rent.overview,
        undefined,
        undefined,
        jwt
      );
      const sortedRentData = sortRentDataByDate(response.response.rent_data);
      setRentOverviewData(response.response.rent_overview);
      setRentData(sortedRentData);
      setFlatData(flattenDataWithRowNumbers(sortedRentData));
    } catch (error) {
      console.error("Error fetching rent overview data:", error);
    }
  };

  //Fetch Buildings
  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const buildingResponse = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );
        setBuildings(buildingResponse.response);
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };

    fetchBuildings();
  }, [jwt]);

  //All the Reports Data
  useEffect(() => {
    fetchRentOverviewData();
  }, [jwt]);

  //fetch Units
  useEffect(() => {
    async function fetchUnits() {
      try {
        const unitResponse = await APICall(
          "GET",
          paths.unit.fetch,
          undefined,
          undefined,
          jwt
        );
        setUnitData(unitResponse.response);
        console.log("Unit Data", unitData);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    }

    fetchUnits();
  }, [jwt]);

  const handleShowUnitModal = () => {
    setShowUnitModal(true);
  };

  const handleCloseUnitModal = () => {
    setShowUnitModal(false);
  };

  const handleApplyUnit = (unitId) => {
    setSelectedUnitId(unitId);
    // Update any filter or state related to units here
  };

  // Fetch tenants data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall("GET", paths.tenant.fetchAll, undefined, undefined, jwt);
        console.log("Response:", response);
        setTenantData(response.response);
        console.log("This is tenant data",tenantData)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [jwt]);

  const handleShowTenantModal = () => {
    setShowTenantModal(true);
  };

  const handleCloseTenantModal = () => {
    setShowTenantModal(false);
  };

  const handleApplyTenant = (tenantId) => {
    setSelectedTenantId(tenantId);
    setFilters((prevFilters) => ({ ...prevFilters, tenant: tenantId }));
    handleCloseTenantModal(); 
  };

  const sortRentDataByDate = (data) => {
    return data.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
  };

  const handleRowClick = (person) => {
    console.log("person: ", person)
    setSelectedTenant(person);
    setShowReportDetailsModal(true);
  };

  // Inside RentOverviewTable component
  const handleMarkAsPaidClick = (person) => {
    // setSelectedTenant(person);
    setShowReportDetailsModal(false); // Close the ReportDetails modal
    setShowPaidModal(true); // Show the PaidModal
  };

  const handleMarkAsPaid = async () => {
    try {
      setIsMarkAsPaidLoading(true);

      if (!selectedPaymentType) {
        console.log("Payment type is required.");
        return;
      }

      let body = {
        nature: selectedTenant.nature,
        payment_id: "manual",
        payment_mode: selectedPaymentType,
        agreement_id: selectedTenant.agreement_id,
        building_id: selectedTenant.building_id,
        unit_id: selectedTenant.unit_id,
        tenant_id: selectedTenant.tenant_id,
        amount: selectedTenant.amount,
        payment_status: "success",
        type: "deposited",
        month: selectedTenant.month,
        year: selectedTenant.year,
        unit_name: selectedTenant.unit_name,
        building_name: selectedTenant.building_name,
        tenant_name: selectedTenant.first_name + " " + selectedTenant.last_name,
      };

      if (selectedTenant.description) {
        body = {
          ...body,
          description: selectedTenant.description,
        };
      }

      if (selectedFile) {
        body = {
          ...body,
          file: selectedFile,
        };
      }

      const response = await APICall(
        "POST",
        paths.rent.markaspaid,
        body,
        undefined,
        jwt
      );

      if (response.status === "success") {
        fetchRentOverviewData();
        setShowPaidModal(false);
      }
    } catch (error) {
      console.log("Error as markAsPaid cannot be done", error);
    } finally {
      setIsMarkAsPaidLoading(false);
    }
  };

  const flattenDataWithRowNumbers = (monthsArray) => {
    let rowNumber = 1;
    return monthsArray.flatMap((month) =>
      month.data.map((item) => ({ ...item, rowNumber: rowNumber++ }))
    );
  };

  const toggleFilter = (filterName) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (filterName === "unit") {
        setShowUnitModal(!showUnitModal);
      } else if (filterName === "building") {
        if (selectedBuildingId) {
          setSelectedBuildingId(null);
          updatedFilters.building = null;
        } else {
          setShowBuildingModal(true);
        }
      } else if (filterName === "tenant") {
        setShowTenantModal(!showTenantModal);
      } else {
        updatedFilters[filterName] = !prevFilters[filterName];
      }

      const newFilterCount =
        Object.values(updatedFilters).filter(Boolean).length;
      setFilterCount(newFilterCount);
      return updatedFilters;
    });
  };

  const applyFilters = (data) => {
    return data.filter((item) => {
      if (filters.overdue && item.due_in !== "Overdue") return false; // Filter out non-overdue items if overdue filter is active
      if (filters.today && !isToday(new Date(item.due_date))) return false; // Check if due_date is today
      if (filters.building && !filters.building.includes(item.building_id))
        return false;
      if (filters.unit && !filters.unit.includes(item.unit_id)) return false;
      if (filters.tenant && !filters.tenant.includes(item.tenant_id))
        return false;
      return true;
    });
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };
  const renderRows = (data) => {
    // Apply filters to get filteredData
    let filteredData = applyFilters(data);

    if (filteredData.length === 0) {
      return (
        <tr>
          <td colSpan="6" className="text-center py-4">
            No records for this filter.
          </td>
        </tr>
      );
    }

    return (
      <React.Fragment>
        {filteredData.map((item, index) => (
          <tr key={`row_${item.tenant_id}_${index}`} className="border-y-2">
            <td>{item.rowNumber}</td>
            {/* <td>{item.title}</td> */}

            <td
              onClick={() => handleRowClick(item)}
              className="flex justify-start items-center gap-4 p-2"
            >
              <CircularInitial
                firstName={item.first_name}
                lastName={item.last_name}
              />
              {`${item.first_name} ${item.last_name}`}
            </td>
            <td className="whitespace-nowrap text-left">
              {`${item.unit_name}, ${item.building_name}`}
            </td>
            <td className="whitespace-nowrap">
              {`${new Date(item.due_date).toLocaleDateString()}`},{" "}
              <OverdueStatus dueDate={item.due_date} dueIn={item.due_in} />
            </td>
            <td className="whitespace-nowrap flex justify-center items-start gap-2">
              <Paid
                onPressMarkAsPaid={() => handleMarkAsPaidClick(item)}
                isMarkAsPaidDisabled={isMarkAsPaidLoading}
              />
              <WhatsAppComponent selectedTenant={item}>
                
              </WhatsAppComponent>
            </td>
            <td className="whitespace-nowrap text-center">{`${item.amount}`}</td>
          </tr>
        ))}
      </React.Fragment>
    );
  };

  const closeBuildingModal = () => {
    setShowBuildingModal(false);
  };

  const handleBuildingSelection = (buildingId) => {
    if (selectedBuildingId === buildingId) {
      setSelectedBuildingId(null);
      setFilters({ ...filters, building: null });
    } else {
      setSelectedBuildingId(buildingId);
      setFilters({ ...filters, building: buildingId });
    }
  };

  const handleUnitSelection = (unitId) => {
    if (selectedUnitId === unitId) {
      // If the selected unit is already the one being clicked, deselect it
      setSelectedUnitId(null);
      setFilters((prevFilters) => ({ ...prevFilters, unit: null }));
    } else {
      // Otherwise, select the new unit and update the filters
      setSelectedUnitId(unitId);
      setFilters((prevFilters) => ({ ...prevFilters, unit: unitId }));
    }
  };

  const handleTenantSelection = (tenantId) => {
    console.log('handleTenantSelection called with tenantId:', tenantId);
    if (selectedTenantId === tenantId) {
      setSelectedTenantId(null);
      setFilters((prevFilters) => ({ ...prevFilters, tenant: null }));
    } else {
      setSelectedTenantId(tenantId);
      setFilters((prevFilters) => ({ ...prevFilters, tenant: tenantId }));
    }
  };

  const uniqueBuildings = Array.from(
    new Set(flatData.map((item) => item.building_id))
  ).map((buildingId) => {
    return flatData.find((item) => item.building_id === buildingId);
  });

  useEffect(() => {
    // Update filter count whenever filters change
    updateFilterCount();
  }, [filters]);

  const updateFilterCount = () => {
    let count = 0;
    if(filters.overdue) count += 1;
    if(filters.today) count += 1;
    if (filters.building) count += 1;
    if (filters.unit) count += 1;
    if (filters.tenant) count += 1;
    setFilterCount(count);
  };

  const toggleBuildingFilter = () => {
    if (selectedBuildingId) {
      setSelectedBuildingId(null);
      setFilters((prevFilters) => ({ ...prevFilters, building: null }));
    } else {
      setShowBuildingModal(true);
    }
  };

  const toggleUnitFilter = () => {
    if (selectedUnitId) {
      // Clear the selected unit ID and update the filters
      setSelectedUnitId(null);
      setFilters((prevFilters) => ({ ...prevFilters, unit: null }));
    } else {
      // Show the UnitModal if no unit is currently selected
      setShowUnitModal(true);
    }
  };

  const toggleTenantFilter = () => {
    if (selectedTenantId) {
      setSelectedTenantId(null);
      setFilters((prevFilters) => ({ ...prevFilters, tenant: null }));
    } else {
      setShowTenantModal(true);
    }
  };

  return (
    <div className="flex flex-col mt-6">
      <div className="filters-container flex items-center gap-4 text-left py-4">
        <button className="border-2 p-2 rounded-lg relative">
          <BsFilterSquare size={20} color="blue" />
          {filterCount > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
              {filterCount}
            </span>
          )}
        </button>

        <button
          className={`border-2 text-xs text-center rounded-lg px-2 py-2 ${
            filters.overdue ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => toggleFilter("overdue")}
        >
          Overdue
        </button>
        <button
          className={`border-2 text-xs text-center rounded-lg px-2 py-2 ${
            filters.today ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => toggleFilter("today")}
        >
          Today
        </button>
        <div className="flex gap-2">
          <button
            className={`flex justify-center items-center border-2 text-xs text-center rounded-lg px-2 py-2 ${
              selectedBuildingId ? "bg-blue-500 text-white" : ""
            }`}
            onClick={toggleBuildingFilter}
          >
            <p>Building</p>
            <IoMdArrowDropdown size={20} />
          </button>
        </div>
        <div>
          <button
            className={`flex justify-center items-center border-2 text-xs text-center rounded-lg px-2 py-2 ${
              filters.unit ? "bg-blue-500 text-white" : ""
            }`}
            onClick={toggleUnitFilter}
          >
            <p>Unit</p>
            <IoMdArrowDropdown size={20} />
          </button>
        </div>

        <div className="filters-container flex items-center gap-4 text-left py-4">
          {/* Other filter buttons */}
          <button
            className={`flex justify-center items-center border-2 text-xs text-center rounded-lg px-2 py-2 ${
              filters.tenant ? "bg-blue-500 text-white" : ""
            }`}
            onClick={toggleTenantFilter}
          >
            Tenant
            <IoMdArrowDropdown size={20} />
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 text-xs">
          <thead>
            <tr>
              <th className="py-3 text-left text-xs font-strong text-black uppercase tracking-wider">
                Sr.No
              </th>
              <th className=" py-3 text-center text-xs font-strong text-black uppercase tracking-wider">
                Description
              </th>
              <th className=" py-3 text-left text-xs font-strong text-black uppercase tracking-wider">
                Tenant Details
              </th>
              <th className=" py-3 text-left text-xs font-strong text-black uppercase tracking-wider">
                Date
              </th>
              <th className="py-3 text-center text-xs font-strong text-black uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-center text-xs font-strong text-black uppercase tracking-wider">
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="">{renderRows(flatData)}</tbody>
        </table>
      </div>

      {/* ReportDetails Modal */}
      {showReportDetailsModal && (
        <ReportDetails
          tenantData={selectedTenant}
          onClose={() => setShowReportDetailsModal(false)}
          onMarkAsPaidClick={handleMarkAsPaidClick} // Pass the function here
        />
      )}

      {/* PaidModal */}
      {showPaidModal && (
        <PaidModal
          onClose={() => setShowPaidModal(false)}
          onMarkAsPaid={handleMarkAsPaid}
          selectedTenant={selectedTenant}
          setSelectedPaymentType={setSelectedPaymentType}
          setSelectedFile={setSelectedFile}
        />
      )}

      <FilterBuildingModal
        show={showBuildingModal}
        handleClose={closeBuildingModal}
        buildings={buildings}
        selectedBuildingId={selectedBuildingId}
        handleApply={handleBuildingSelection}
      />
      <UnitModal
        show={showUnitModal}
        handleClose={handleCloseUnitModal}
        units={unitData}
        selectedUnitId={selectedUnitId}
        handleApply={handleUnitSelection}
      />

      <TenantModal
        show={showTenantModal}
        handleClose={handleCloseTenantModal}
        tenantData={tenantData}
        selectedTenantId={selectedTenantId}
        handleApply={handleTenantSelection}
      />
    </div>
  );
}

export default RentOverviewTable;
