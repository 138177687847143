import React from "react";

function OverdueStatus({ dueDate, dueIn }) {
  // Function to calculate the difference in days between due date and current date
  const calculateDaysDifference = (dueDate) => {
    const currentDate = new Date();
    const dueDateObj = new Date(dueDate);
    const timeDifference = dueDateObj.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  };

  // Function to determine the overdue status
  const determineOverdueStatus = (daysDifference) => {
    if (daysDifference < 0) {
        return "Overdue";
    } else if (daysDifference === 0) {
        return "Due today";
    } else if (daysDifference <= 7) {
        return `due in ${daysDifference} days`;
    } else {
        return `due in ${daysDifference} days`;
    }
};

  const daysDifference = calculateDaysDifference(dueDate);
  const overdueStatus = determineOverdueStatus(daysDifference);

  // Determine text color based on daysDifference
  const textColor = daysDifference <= 7 ? "red" : "orange";

  return <span style={{ color: textColor }}>{overdueStatus}</span>;
}

export default OverdueStatus;
