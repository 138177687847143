import React from 'react';

function StatusBtn({ label, className, icon }) {
  return (
    <button className={`px-4 py-2 rounded-full flex items-center space-x-2 ${className}`}>
      {icon}
      <span>{label}</span>
    </button>
  );
}

export default StatusBtn;
