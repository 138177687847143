import React from 'react';
import { useLocation } from 'react-router-dom';

function ViewAll() {
    const location = useLocation();
    const { rentData } = location.state || {}; // Ensure rentData is defined

    return (
        <div>
            {rentData && rentData.map((rentalData) => (
                rentalData.data.map((rental) => (
                    <div key={rental.id}>
                        {/* Render each rental item */}
                        <p>{rental.first_name} {rental.last_name}</p>
                        {/* Add more details as needed */}
                    </div>
                ))
            ))}
        </div>
    );
}

export default ViewAll;
