import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";

const BuildingDetails = () => {
  const { buildingId } = useParams();
  const location = useLocation();

  const building = location.state || {};
  // console.log("Test building", location.state);

  const [units, setUnits] = useState([]);

  const { jwt } = useAuth();

  const [buildingDetails, setBuildingDetails] = useState(null);

  useEffect(() => {
    if (building && building.entity_id) {
      const fetchBuildingDetails = async () => {
        try {
          const body = {
            building_id: building.entity_id,
          };
          const response = await APICall(
            "GET",
            paths.unit.fetch,
            undefined,
            body,
            jwt
          );
          setBuildingDetails(response.response);
        } catch (error) {
          console.error("Error fetching Building Details:", error);
        }
      };

      fetchBuildingDetails();
    }
  }, [building, jwt]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unitResponse = await APICall(
          "GET",
          paths.unit.fetch,
          undefined,
          undefined,
          jwt
        );

        // Filter units based on entity_id
        const filteredUnits = unitResponse.response.filter(
          (unit) => unit.building_id === building.entity_id
        );

        setUnits(filteredUnits);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (building) {
      fetchData();
    }
  }, [building, jwt]);

  if (!buildingDetails) return <div>Loading...</div>;

  return (
    <div>
      {/* <p>{building.entity_id}</p> */}
      {/* <p>Building Name: {building.building_name}</p> */}
      {/* <p>Address: {building.address}</p> */}
      {/* Add more building details here */}

      <h2>Units</h2>
      {units && units.length > 0 ? (
        <ul>
          {units.map((unit) => (
            <li key={unit.id}>
              <p>Unit Name: {unit.unit_name}</p>
              <p>Building Name: {unit.building_name}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No units available for this building.</p>
      )}
    </div>
  );
};

export default BuildingDetails;
