
export function shortIndianNumberingFormat(totalRent, x = 2) {
    const rentNumber = typeof totalRent === 'string' ? parseFloat(totalRent) : totalRent;
    let formattedRent = '';
    let toAppend = '';
    if (rentNumber >= 10000000) {
      formattedRent = `₹${(rentNumber / 10000000).toFixed(x)}`;
      toAppend = 'Cr';
    } else if (rentNumber >= 100000) {
      formattedRent = `₹${(rentNumber / 100000).toFixed(x)}`;
      toAppend = 'L';
    } else {
      formattedRent = `₹${(rentNumber / 1000).toFixed(x)}`;
      toAppend = 'K';
    }
  
    while (formattedRent.endsWith('0')) {
      formattedRent = formattedRent.slice(0, -1);
    }
    formattedRent.endsWith('.') && (formattedRent = formattedRent.slice(0, -1));
  
    formattedRent += toAppend;
  
    return formattedRent;
  }

  export function calculateDueDate(dueIn) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - dueIn);
  
    // Format date as YYYY-MM-DD without time and GMT
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  export function extractDatePart(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  export function getOrdinalSuffix(date) {
    const j = date % 10;
    const k = date % 100;
    if (j === 1 && k !== 11) {
      return date + "st";
    }
    if (j === 2 && k !== 12) {
      return date + "nd";
    }
    if (j === 3 && k !== 13) {
      return date + "rd";
    }
    return date + "th";
  };

