import React from 'react'

function AddNewTenant() {
  return (
    <div className='p-10 border-2 rounded-2xl'>
        <p className='font-black'>Add New Tenant</p>

        
    </div>
  )
}

export default AddNewTenant