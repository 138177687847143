import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase';

const FileDownload = ({ filePath }) => {
  const [downloadURL, setDownloadURL] = useState('');

  useEffect(() => {
    const fetchDownloadURL = async () => {
      const storageRef = ref(storage, filePath);
      try {
        const url = await getDownloadURL(storageRef);
        setDownloadURL(url);
      } catch (error) {
        console.error('Error fetching download URL:', error);
      }
    };

    fetchDownloadURL();
  }, [filePath]);

  if (!downloadURL) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <img src={downloadURL} alt="Downloaded File" />
    </div>
  );
};

export default FileDownload;
