// src/components/AddNewBuilding.js
import React, { useState, useEffect } from 'react';
import Layout from "../Layout";
import APICall from "../../Utility/APICall";
import { paths, cloudStorageBucket } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FileUpload from '../../Utility/FileUpload';
import FileDownload from '../../Utility/FileDownload';
import { GetUploadMediaBaseLocation } from '../../Utility/APICall';
import {storage} from '../../Utility/firebase'
import { getDownloadURL} from 'firebase/storage';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useUser } from '../../Utility/UserProvider';

const AddNewBuilding = () => {
 const { jwt } = useAuth();
  const amenitiesList = ["Free Parking", "Gate Security", "CCTV", "WiFi"];
  const location = useLocation();
  const buildingData = location.state?.building || {};
  const { user } = useUser();
  const [file, setFile] = useState(null);
  const [downloadURL, setDownloadURL] = useState("");

  const [formData, setFormData] = useState({
    buildingName: buildingData.building_name || "",
    locality: buildingData.address || "",
    numberOfFlats: buildingData.number_of_flats || 1,
    numberOfFloors: buildingData.number_of_floors || 1,
    amenities: buildingData.amenities || [],
    imageUrl: buildingData.imageUrl || ""
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isBuildingAddFailed, setIsBuildingAddFailed] = useState(false);

  const navigate = useNavigate();

  const handleIncrementFloors = () => {
    setFormData((prevData) => ({
      ...prevData,
      numberOfFloors: prevData.numberOfFloors + 1,
    }));
  };

  const handleDecrementFloors = () => {
    if (formData.numberOfFloors > 1) {
      setFormData((prevData) => ({
        ...prevData,
        numberOfFloors: prevData.numberOfFloors - 1,
      }));
    }
  };

  const handleIncrementFlats = () => {
    setFormData((prevData) => ({
      ...prevData,
      numberOfFlats: prevData.numberOfFlats + 1,
    }));
  };

  const handleDecrementFlats = () => {
    if (formData.numberOfFlats > 1) {
      setFormData((prevData) => ({
        ...prevData,
        numberOfFlats: prevData.numberOfFlats - 1,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        amenities: [...prevData.amenities, id],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        amenities: prevData.amenities.filter((amenity) => amenity !== id),
      }));
    }
  };

  const handleFileUpload = (file) => {
    setFile(file);
  };

  const handleAddBuildingWithoutMediaToDatastore = async () => {
    const body = {
      building_name: formData.buildingName,
      number_of_flats: formData.numberOfFlats,
      number_of_floors: formData.numberOfFloors,
      address: formData.locality,
      amenities: formData.amenities,
      status: "active",
    };

    try {
      setIsLoading(true);
      const res = await APICall("POST", paths.building.add, body, undefined, jwt);

      if (res.status === "success") {
        setIsLoading(false);
        setFormData({
          buildingName: "",
          locality: "",
          numberOfFlats: 1,
          numberOfFloors: 1,
          amenities: [],
          imageUrl: "",
        });
        alert("Building added successfully!");
        return res.response.entity_id;
      } else {
        console.error("Error", "Error in adding building and fetching building_id");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMediaToBuildingToDatastore = async (building_id) => {
    try {
      const baseUploadLocation = GetUploadMediaBaseLocation(cloudStorageBucket.buildings, user);
      const media = {
        name: file.name,
        location: `${baseUploadLocation}${building_id}/${file.name}`,
        type: file.type,
        height: file.height,
        width: file.width,
        size: file.size,
        description: file.description
      };

      const body = {
        building_id: building_id,
        media: JSON.stringify(media)
      };

      const response = await APICall("PUT", paths.building.update, body, undefined, jwt);
      console.log(response);

      alert("Media added to Building successfully");
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddMediaWithFirebaseStorageUpload = async () => {
    try {
      const building_id = await handleAddBuildingWithoutMediaToDatastore();
      const baseUploadLocation = GetUploadMediaBaseLocation(cloudStorageBucket.buildings, user);
      const location = `${baseUploadLocation}${building_id}/${file.name}`;
      const reference = ref(storage, location);
      const uploadTask = uploadBytesResumable(reference, file);

      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        error => {
          console.error("Upload failed", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref); // Corrected usage
          console.log("Download URL:", downloadURL);
          await handleAddMediaToBuildingToDatastore(building_id, downloadURL);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    // <Layout>
      <div>
        <div style={{ marginBottom: "20px" , backgroundColor: 'red'}}>
          <FileUpload onFileUpload={handleFileUpload} />
        </div>

        {formData.imageUrl && (
          <div style={{ marginBottom: "20px" }}>
            <FileDownload filePath={formData.imageUrl} />
          </div>
        )}

        <div style={{ display: "flex", alignItems: "start", gap: 10, marginTop: 20 }}>
          <div style={{ flex: 0.5 }}>
            <p style={{ fontSize: 20, fontWeight: 700 }}>Building Details</p>

            <div style={{ marginTop: "24px" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "24px", flex: 0.85 }}>
                <input
                  id="building-name"
                  name="buildingName"
                  placeholder="Building Name"
                  value={formData.buildingName}
                  onChange={handleInputChange}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px",
                    marginBottom: "16px",
                  }}
                />

                <input
                  id="locality"
                  name="locality"
                  placeholder="Locality/Area"
                  value={formData.locality}
                  onChange={handleInputChange}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px",
                    marginBottom: "16px",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: ".5px solid gray",
                  paddingTop: "20px",
                  marginBottom: 20,
                }}
              >
                <p>No. of Flats</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button onClick={handleIncrementFlats}>
                    <ControlPointIcon />
                  </button>
                  <p>{formData.numberOfFlats}</p>
                  <button onClick={handleDecrementFlats}>
                    <RemoveCircleOutlineIcon />
                  </button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: ".5px solid gray",
                  paddingTop: "20px",
                }}
              >
                <p>No. of Floors</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button onClick={handleIncrementFloors}>
                    <ControlPointIcon />
                  </button>
                  <p>{formData.numberOfFloors}</p>
                  <button onClick={handleDecrementFloors}>
                    <RemoveCircleOutlineIcon />
                  </button>
                </div>
              </div>

              <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                <button
                  type="button"
                  // onClick={handleAddBuildingToDatastore}
                  onClick={handleAddMediaWithFirebaseStorageUpload}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {isLoading ? "Saving..." : "Save Building"}
                </button>
                {isBuildingAddFailed && <p style={{ color: "red" }}>Failed to add building.</p>}
              </div>
            </div>
          </div>

          <div style={{ flex: 0.5, marginLeft: 20 }}>
            <p style={{ fontSize: 20, fontWeight: 700, paddingBottom: 20 }}>Amenities</p>

            <div>
              {amenitiesList.map((amenity) => (
                <div key={amenity} style={{ marginBottom: "8px" }}>
                  <input
                    type="checkbox"
                    id={amenity}
                    checked={formData.amenities.includes(amenity)}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={amenity} style={{ marginLeft: "8px" }}>
                    {amenity}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    // </Layout>
  );
};

export default AddNewBuilding;
