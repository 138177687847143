// src/components/CustomButton.js
import React from "react";

const CustomButton = ({ tailwindClasses, label, onClick }) => {
  return (
    <button
      className={`py-2 px-4 font-semibold rounded-lg shadow-md ${tailwindClasses}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default CustomButton;
