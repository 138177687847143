import React, { useEffect, useState } from "react";
import "./property.css";
import { paths } from "../../Utility/Constants";
import APICall from "../../Utility/APICall";
import { useAuth } from "../../Utility/AuthProvider";
import { GetMediaURL } from "../../Utility/APICall";
import RentedBtn from "../../components/Buttons/RentedBtn";
import VacantBtn from "../../components/Buttons/VacantBtn";
import CircularMeter from "./CircularMeter";

// import { getImageFile } from "../../Utility/APICall";

function Property() {
  const { jwt } = useAuth();
  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
  const [modifiedArr, setModifiedArr] = useState([]);
  const [imagelocation, setImageLocation] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [mediaArray, setMediaArray] = useState([]);
  const [oneImage, setOneImage] = useState("");
  const [twoImage, setTwoImage] = useState("");
  const [threeImage, setThreeImage] = useState("");

  const [finalImageURLs, setFinalImageURLs] = useState([]);

  const getImageFile = async (path, location, jwt, params) => {
    let final_url =
      "https://renown-backend-assignment.el.r.appspot.com" +
      path +
      "?location=" +
      location;

    if (params) {
      const paramsString = new URLSearchParams(params).toString();
      if (paramsString.length > 0) {
        final_url += "&" + paramsString;
      }
    }

    let headers = new Headers();
    headers.append("Authorization", jwt);

    try {
      const response = await fetch(final_url, {
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
      // setOneImage(objectURL)
    } catch (error) {
      console.error("Error fetching image:", error);
      return "";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall(
          "GET",
          paths.unit.fetch,
          undefined,
          undefined,
          jwt
        );

        let propertyArr = response.response;

        if (propertyArr.length > 3) {
          const newArray = propertyArr.slice(0, 3);
          setModifiedArr(newArray);
        } else {
          setModifiedArr(propertyArr);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading state to false regardless of success or failure
      }
    };

    fetchData(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  useEffect(() => {
    let temp = [];
    modifiedArr.map((item) => {
      try {
        console.log("location item: ", item.media[0]?.location);
        temp.push(item.media[0]?.location);
      } catch (err) {
        console.log("error: ", err);
      }
    });
    setMediaArray(temp);
  }, [modifiedArr]);

  useEffect(() => {
    const showImages = async () => {
      const image1 = await getImageFile(
        paths.media.download,
        mediaArray[0],
        jwt
      );
      const image2 = await getImageFile(
        paths.media.download,
        mediaArray[1],
        jwt
      );
      const image3 = await getImageFile(
        paths.media.download,
        mediaArray[2],
        jwt
      );

      setOneImage(image1);
      setTwoImage(image2);
      setThreeImage(image3);
    };

    showImages();
  }, [mediaArray]);

  return (
    <div className="property">
      {isLoading ? (
        <div>Loading...</div> // Render loader if loading state is true
      ) : (
        <>
          <div className="flex gap-10 flex-col">
            {modifiedArr.map((item, index) => {
              const imageurl =
                "http://renown-backend-asssignment/192.168.1.44:5000/media/download?location=5638358357245952/user-property-media/buildings/5476269378502656/092DE0B6-DEA6-42C1-B8AF-579DA54B7A23.jpg&quality=10";
              // console.log("image url: ", imageurl);
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <div className="property-img">
                    {index === 1 ? (
                      <img src={oneImage} />
                    ) : index === 2 ? (
                      <img src={twoImage} />
                    ) : (
                      <img src={threeImage} />
                    )}
                  </div>
                  <div className="unit-details">
                    <div>{item.unit_name}</div>
                    <div>{item.building_name}</div>
                  </div>
                  <div>
                    {item.occupancy_status === "vacant" ? (
                      <VacantBtn>{item.occupancy_status}</VacantBtn>
                    ) : (
                      <RentedBtn>{item.occupancy_status}</RentedBtn>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className="graph-container">
        <CircularMeter totalFlats={50} rentedFlats={10} vacantFlats={40} />
      </div>
    </div>
  );
}

export default Property;
