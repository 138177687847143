import React from "react";
import CustomSidebar from "./CustomSidebar"; // Adjust the import path as necessary
import Navbar from "./Navbar"; // Adjust the import path as necessary
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="flex h-screen">
      <div
        className="fixed top-0 left-0 h-full w-64"
        style={{
          // background: 'rgb(0,133,255)',
          // background: 'linear-gradient(241deg, rgba(0,133,255,1) 0%, rgba(13,10,150,1) 35%)'
        }}
      >
        <CustomSidebar />
      </div>
      <div className="flex flex-col flex-1 ml-64">
        <div
          style={{
            background: 'rgb(0,133,255)',
            background: 'linear-gradient(241deg, rgba(0,133,255,1) 0%, rgba(13,10,150,1) 35%)'
          }}
        >
          <Navbar />
        </div>
        <div className="flex flex-col flex-1 p-4 bg-gray-100 overflow-auto">
          <Outlet />
         
        </div>
      </div>
    </div>
  );
};

export default Layout;
