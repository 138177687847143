import React, { useState, useEffect } from "react";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";

const CustomComponent = ({ displayAll }) => {
  const { jwt } = useAuth();
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [nextPageToken, setNextPageToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (pageToken) => {
    try {
      if (nextPageToken === "END") return;
      setLoading(true);
      const response = await APICall(
        "POST",
        paths.payments.fetch,
        { next_page_token: pageToken ?? "" },
        undefined,
        jwt
      );

      let newPayments = response.response.payments;
      if (!displayAll) {
        newPayments = newPayments.slice(0, 5); // Limit to 5 records if displayAll is false
      }

      const updatedData = [...data, ...newPayments]; // Append new data
      setData(updatedData);
      setGroupedData(groupByMonth(updatedData));
      setNextPageToken(response.response.next_page_token);
      console.log("Record of Payments", updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const groupByMonth = (data) => {
    return data.reduce((acc, item) => {
      const month = new Date(item.local_date).toLocaleString("default", { month: "long", year: "numeric" });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});
  };

  useEffect(() => {
    fetchData(null);
  }, [jwt]);

  const handleNext = () => {
    if (nextPageToken && nextPageToken !== "END") {
      fetchData(nextPageToken);
    }
  };

  const downloadCSV = () => {
    const headers = ["Description", "Tenant Details", "Date", "Status", "Amount"];
    const rows = data.map((item) => [
      `"${item.nature.charAt(0).toUpperCase() + item.nature.slice(1)}"`,
      `"${item.tenant_name}"`,
      `"${item.local_date}"`,
      `"${item.payment_status}"`,
      `"${item.local_amount.replace(/[\₹,]/g, "")}"`,
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "payments_data.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const calculateSerialNumber = (monthIndex, index) => {
    const previousMonthsData = Object.keys(groupedData)
      .slice(0, monthIndex)
      .reduce((acc, month) => acc + groupedData[month].length, 0);
    return previousMonthsData + index + 1;
  };

  return (
    <div className="table-container" style={{ marginBottom: "20px" }}>
      <button
        style={{ padding: "10px", backgroundColor: "green", marginBottom: "20px", color: "white", borderRadius: "10px" }}
        onClick={downloadCSV}
      >
        Download Displayed Record
      </button>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }} className="text-xs">
          <tr>
            <th style={tableHeaderStyle}>Record No.</th>
            <th style={tableHeaderStyle}>Description</th>
            <th style={tableHeaderStyle}>Tenant Details</th>
            <th style={tableHeaderStyle}>Date</th>
            <th style={tableHeaderStyle}>Rent For Month</th>
            <th style={tableHeaderStyle}>Status</th>
            <th style={tableHeaderStyle}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedData).map((month, monthIndex) => (
            <React.Fragment key={month}>
              {groupedData[month].map((innerItem, index) => (
                <tr key={index} style={tableRowStyle} className="text-xs">
                  <td style={tableCellStyle}>{calculateSerialNumber(monthIndex, index)}</td>
                  <td style={tableCellStyle}>{innerItem.nature.charAt(0).toUpperCase() + innerItem.nature.slice(1)}</td>
                  <td style={tableCellStyle}>{innerItem.tenant_name}</td>
                  <td style={tableCellStyle}>{innerItem.local_date}</td>
                  <td style={tableCellStyle}>{month}</td>
                  <td style={tableCellStyle}>{innerItem.payment_status}</td>
                  <td style={tableCellStyle}>{innerItem.local_amount}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
          {loading && (
            <tr>
              <td colSpan="7" style={{ textAlign: "center" }}>
                Loading...
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {nextPageToken === "END" && <p className="text-center p-4">******END OF PAYMENTS DATA******</p>}
      {displayAll && (
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <button
            onClick={handleNext}
            disabled={!nextPageToken || nextPageToken === "END" || loading}
            style={nextPageToken === "END" ? disabledButtonStyle : buttonStyle}
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

const tableHeaderStyle = {
  backgroundColor: "#F5F7FA",
  padding: "10px",
  borderBottom: "1px solid #E1E8F0",
  textAlign: "left",
};

const tableRowStyle = {
  borderBottom: "1px solid #E1E8F0",
};

const tableCellStyle = {
  padding: "10px",
  textAlign: "left",
};

const buttonStyle = {
  padding: "10px 30px",
  backgroundColor: "blue",
  color: "white",
  cursor: "pointer",
  borderRadius: "10px",
};

const disabledButtonStyle = {
  padding: "10px 30px",
  borderRadius: "10px",
  backgroundColor: "gray",
  color: "white",
  cursor: "not-allowed",
};

export default CustomComponent;
