import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import CircularInitial from "../UpcomingRent/CircularInitial";
import PhoneIcon from "../../assets/DashboardIcons/Phone.svg";
import CustomImage from "../../Utility/CustomImage";
import VacantBtn from "../../components/Buttons/VacantBtn";
import RentedBtn from "../../components/Buttons/RentedBtn";
import { PiBuildingsLight } from "react-icons/pi";
import WhatsAppComponent from "../Reports/components/WhatsappComponent";
import { extractDatePart, calculateDueDate } from "../../Utility/Utils";
import Facility from "./Facility";
import CustomModalEditUnit from "../Modals/CustomModalEditUnit";
import CustomButton from "../Buttons/CustomButton";
import MonthIcons from "../../Utility/MonthIcons";
import TenantDetailsModal from "../Modals/TenantDetailsModal";
import AddAgreementModal from "../Modals/AddAgreementModal";

import { IoIosBed, IoIosWifi, IoIosSnow } from "react-icons/io";
import { BiCctv } from "react-icons/bi";
import { PiTelevisionDuotone } from "react-icons/pi";
import { CiParking1 } from "react-icons/ci";
import { LuRefrigerator } from "react-icons/lu";
import { MdLocalLaundryService } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { CiPower } from "react-icons/ci";
import { PiFan } from "react-icons/pi";
import { FaHandsHelping } from "react-icons/fa";

function UnitDetails() {
  const location = useLocation();
  const { jwt } = useAuth();
  const [unitDetails, setUnitDetails] = useState(null);
  const [units, setUnits] = useState([]);
  const [tenantData, setTenantData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [loadingBuildings, setLoadingBuildings] = useState(true);
  const [errorBuildings, setErrorBuildings] = useState(null);
  const [tenantDetailsModal, setTenantDetailsModal] = useState(false);
  const [showAddAgreementModal, setShowAddAgreementModal] = useState(false);

  const unit = location.state || {};
  // console.log("tenant data -",tenantData)

  useEffect(() => {
    if (unit && unit.entity_id) {
      const fetchUnitDetails = async () => {
        try {
          const body = { unit_id: unit.entity_id };
          const response = await APICall(
            "GET",
            paths.unit.view,
            undefined,
            body,
            jwt
          );
          setUnitDetails(response.response);
          console.log("Unit Response", response);
        } catch (error) {
          console.error("Error fetching Unit Details:", error);
        }
      };

      fetchUnitDetails();
    }
  }, [unit, jwt]);

  // Fetch buildings
  useEffect(() => {
    const fetchBuildings = async () => {
      setLoadingBuildings(true); // Start loading
      try {
        const response = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );
        // console.log("API Response:", response);
        setBuildings(response.response);
        console.log("Response for fetch buildings:", response.response); // Confirm state update
      } catch (error) {
        setErrorBuildings(error);
        console.error("Error fetching buildings:", error);
      } finally {
        setLoadingBuildings(false);
        // console.log("Fetching complete, loading state set to false."); // Confirm completion
      }
    };

    fetchBuildings();
  }, [jwt]); // Dependency array ensures this runs when `jwt` changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall(
          "GET",
          paths.tenant.fetchAll,
          undefined,
          undefined,
          jwt
        );
        setTenantData(response.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [jwt]);

  const handleUpdateSuccess = async () => {
    console.log("Update successful, fetching updated unit details...");

    try {
      const body = { unit_id: unit.entity_id };
      const response = await APICall(
        "GET",
        paths.unit.view,
        undefined,
        body,
        jwt
      );
      // console.log("Updated unit details fetched:", response);
      setUnitDetails(response.response);
    } catch (error) {
      console.error("Error fetching updated unit details:", error);
    }

    setIsModalOpen(false);
  };

  useEffect(() => {
    if (unit && unit.entity_id) {
      console.log("Fetching initial unit details...");
      const fetchUnitDetails = async () => {
        try {
          const body = { unit_id: unit.entity_id };
          const response = await APICall(
            "GET",
            paths.unit.view,
            undefined,
            body,
            jwt
          );
          // console.log("Unit details fetched:", response);
          setUnitDetails(response.response);
        } catch (error) {
          console.error("Error fetching Unit Details:", error);
        }
      };

      fetchUnitDetails();
    }
  }, [unit, jwt]);

  const handleAddAgreement = async () => {
    try {
      const body = {
        unit_id: unit.entity_id,
        tenant_id: unitDetails.tenant?.entity_id, // Make sure tenant data exists
      };
      await APICall("PUT", paths.agreement.add, undefined, body, jwt);
      // Handle success, e.g., refresh unit details or show a success message
      console.log("Agreement added successfully");
    } catch (error) {
      console.error("Error adding agreement:", error);
    } finally {
      setShowAddAgreementModal(false); // Close the modal after the API call
    }
  };

  if (loadingBuildings) return <p>Loading buildings...</p>;
  if (errorBuildings)
    return <p>Error loading buildings: {errorBuildings.message}</p>;

  if (!unitDetails) {
    return <p>No unit data available</p>;
  }

  const { agreement, tenant, transactions, unitdata } = unitDetails;
  const agreementDetails = agreement || {};
  const amenitiesList = unitDetails.unit.amenities
    ? unitDetails.unit.amenities.split(",")
    : [];

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    return nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();
  };

  const mapAmenitiesToIcons = (amenity) => {
    switch (amenity.trim().toLowerCase()) {
      case "wifi":
        return { icon: IoIosWifi, name: "WiFi" };
      case "cctv":
        return { icon: BiCctv, name: "CCTV" };
      case "television":
        return { icon: PiTelevisionDuotone, name: "Television" };
      case "parking":
        return { icon: CiParking1, name: "Parking" };
      case "refrigerator":
        return { icon: LuRefrigerator, name: "Refrigerator" };
      case "laundry":
        return { icon: MdLocalLaundryService, name: "Laundry" };
      case "security":
        return { icon: MdOutlineSecurity, name: "Security" };
      case "inverter":
        return { icon: CiPower, name: "Inverter" };
      case "bed":
        return { icon: IoIosBed, name: "Bed" };
      case "fan":
        return { icon: PiFan, name: "fan" };
      case "AC":
        return { icon: IoIosSnow, name: "AC" };
      default:
        return { icon: FaHandsHelping, name: "Friendly" };
    }
  };

  // const handleUpdate = (updatedUnitDetails) => {
  //   // Find the index of the unit that was updated
  //   const updatedUnits = units.map((unit) =>
  //     unit.entity_id === updatedUnitDetails.unit.entity_id
  //       ? updatedUnitDetails.unit
  //       : unit
  //   );

  //   // Update the state with the new list of units
  //   setUnits(updatedUnits);
  // };

  return (
    <div className="text-xs border-2 rounded-2xl p-10">
      {/* row1 */}
      <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Container for Unit Details and Agreement Details */}
        <div className="flex flex-col justify-between rounded-lg p-4">
          {/* Unit Details Container */}
          <div className="flex flex-col mb-4">
            <div className="flex items-center justify-between mb-4">
              <p className="text-xl font-semibold">Unit</p>
              {agreement ? <WhatsAppComponent selectedTenant={tenant} /> : null}
            </div>
            <div className="bg-white rounded-lg p-5 flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <p className="text-lg pr-4">{unitDetails.unit.unit_name}</p>
                    {unitDetails.unit.occupancy_status === "vacant" ? (
                      <VacantBtn>Vacant</VacantBtn>
                    ) : (
                      <RentedBtn>Rented</RentedBtn>
                    )}
                  </div>
                </div>
                <p>{unitDetails.unit.building_name}</p>
                <p>{unitDetails.unit.address}</p>
                <p className="font-bold mt-4">Rent</p>
                <p>{unitDetails.unit.rent}</p>
              </div>
              <div className="rounded-lg flex justify-center items-center">
                {unitDetails.unit.media && unitDetails.unit.media.length > 0 ? (
                  <CustomImage
                    location={unitDetails.unit.media[0].location}
                    jwt={jwt}
                    initials={getInitials(unitDetails.unit.entity_id)}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div
                    className="text-white text-xl flex items-center justify-center"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <PiBuildingsLight size={100} color="grey" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Agreement Details */}
          <p className="text-xl font-semibold mb-4">Agreement Details</p>
          <div className="bg-white rounded-lg border-1 p-10">
            {agreement ? (
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Monthly Rent</strong>
                    {agreementDetails.rent[0].rent}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Due Date</strong>
                    {calculateDueDate(agreementDetails.due_date)}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Staying From</strong>
                    {extractDatePart(agreementDetails.staying_from)}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Security Deposit</strong>
                    {agreementDetails.security_deposit}
                  </p>
                </div>
                <div className="flex-1">
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Maintenance</strong>
                    {agreementDetails.maintenance}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Tenure</strong>
                    {agreementDetails.tenure}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Vacating On</strong>
                    {extractDatePart(agreementDetails.vacating_on)}
                  </p>
                </div>
              </div>
            ) : (
              <div className="mb-4 p-10 flex flex-col justify-between items-center">
                You don’t have any tenant right now. Please create your
                agreement.
                <button
                  onClick={() => setShowAddAgreementModal(true)}
                  className="bg-black text-white w-40 py-2 rounded-md mt-10"
                >
                  Create Agreement
                </button>
                <AddAgreementModal
                  isOpen={showAddAgreementModal}
                  onClose={() => setShowAddAgreementModal(false)}
                  onAddAgreement={handleAddAgreement}
                  unitDetails={unitDetails}
                />
              </div>
            )}
          </div>
        </div>

        {/* Container for Transactions and Tenant Details */}
        <div className="flex flex-col justify-between rounded-lg p-4">
          {/* Transactions Container */}
          <div className="rounded-lg mb-4">
            <div className="flex justify-between items-center ">
              <p className="text-xl font-semibold">Transactions</p>
              <p>View All</p>
            </div>
            <div className="border bg-white rounded-lg mt-4 p-10">
              <div className="border-b border-gray-500 flex justify-center items-center gap-4 p-2">
                <p>Active</p>
                <p>Archive</p>
              </div>
              {transactions && transactions.length > 0 && (
                <div className="mt-2 flex flex-col gap-5">
                  {transactions.map((transaction, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <div className="flex items-center gap-2">
                        <MonthIcons month={transaction.month} />
                        <div>
                          <p>
                            {transaction.nature} received for{" "}
                            {transaction.month}
                          </p>
                          <p>{transaction.created}</p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <p>{transaction.amount}</p>
                        <p>
                          {transaction.payment_status === "success" ? (
                            <CustomButton
                              tailwindClasses="bg-green-500 h-4 text-xs flex justify-center items-center text-white"
                              label="Paid"
                              onClick={() => setIsModalOpen(true)}
                            />
                          ) : (
                            <CustomButton
                              tailwindClasses="bg-blue-500 text-white"
                              label="Pending"
                              onClick={() => setIsModalOpen(true)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Tenant Details Container */}
          <p className="text-xl font-semibold ">Tenant Details</p>
          <div className="rounded-lg bg-white p-10 flex flex-col gap-4">
            <div className="flex items-center gap-4">
              {unitDetails.tenant ? (
                <>
                  {unitDetails.tenant.media &&
                  unitDetails.tenant.media.profile_picture &&
                  unitDetails.tenant.media.profile_picture.location ? (
                    <CustomImage
                      location={
                        unitDetails.tenant.media.profile_picture.location
                      }
                      jwt={jwt}
                      initials={getInitials(
                        unitDetails.tenant.first_name,
                        unitDetails.tenant.last_name
                      )}
                      className="h-20 w-20 rounded-full"
                    />
                  ) : (
                    <div className="h-20 w-20 flex items-center justify-center rounded-full bg-gray-200">
                      {getInitials(
                        unitDetails.tenant.first_name,
                        unitDetails.tenant.last_name
                      )}
                    </div>
                  )}

                  <div className="flex flex-col">
                    <p>{unitDetails.tenant.first_name}</p>
                    <p>{unitDetails.tenant.mobile}</p>
                    <WhatsAppComponent label={"chat"} selectedTenant={tenant} />
                  </div>
                </>
              ) : (
                <p>No tenant data available.</p>
              )}
            </div>
            {/* Conditionally render the button and modal */}
            {tenant && (
              <CustomButton
                tailwindClasses="bg-blue-500 text-white"
                label="See Details"
                onClick={() => setTenantDetailsModal(true)}
              />
            )}

            {tenantDetailsModal && unitDetails && (
              <TenantDetailsModal
                isOpen={tenantDetailsModal}
                onClose={() => setTenantDetailsModal(false)}
                unitDetails={unitDetails}
              />
            )}
          </div>
        </div>
      </div>

      {/* row3 */}
      <p className="text-xl font-semibold pl-8 pt-4 pb-4">Unit Details</p>
      <div className="bg-white rounded-lg shadow-md p-10">
        <div className="flex justify-between">
          <div className="w-1/2 flex flex-col gap-2">
            <div>
              <p>{unitDetails.unit.unit_name}</p>
              <p>{unitDetails.unit.address}</p>
            </div>

            <div className="flex justify-between">
              <div>
                <p>Flat Area</p>
                <p>{unitDetails.unit.unit_area}</p>
              </div>
              <div>
                <p>BHK</p>
                <p>{unitDetails.unit.bhk}</p>
              </div>
              <div>
                <p>Furnishing</p>
                <p>{unitDetails.unit.furnishing_status}</p>
              </div>
            </div>
          </div>
          <div>
            {/* <button>Edit</button> */}
            <CustomButton
              tailwindClasses="bg-blue-500 text-white"
              label="Edit"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
        <div className="">
          {amenitiesList.length > 0 && (
            <>
              <div className="flex justify-between items-center p-2 bg-gray-100 rounded-md mt-4">
                {amenitiesList.map((amenity, index) => (
                  <li key={index}>{amenity.trim()}</li>
                ))}
              </div>
              <div className="flex flex-wrap gap-4 p-2 rounded-md mt-4">
                {amenitiesList.map((amenity, index) => {
                  const { icon, name } = mapAmenitiesToIcons(amenity);
                  return <Facility key={index} name={name} icon={icon} />;
                })}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Modal for editing unit */}
      <div>
        {isModalOpen && unitDetails && (
          <CustomModalEditUnit
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            unitDetails={unitDetails}
            buildings={buildings}
            onUpdate={handleUpdateSuccess}
          />
        )}
      </div>
    </div>
  );
}

export default UnitDetails;
