import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/Group 1261153494.png";
import HomeIcon from "../assets/SideBarIcons/Home.png";
import PaymentsIcon from "../assets/SideBarIcons/Payments.png";
import TenantsIcon from "../assets/SideBarIcons/Tenants.png";
import PropertiesIcon from "../assets/SideBarIcons/Properties.png";
import RentIcon from "../assets/SideBarIcons/Rent.png";
import AgreementsIcon from "../assets/SideBarIcons/Agreements.png";
import ReportsIcon from "../assets/SideBarIcons/Reports.png";
import SettingsIcon from "../assets/SideBarIcons/Settings.png";

const iconStyle = "w-6 h-6 mr-2";

export default function CustomSidebar() {
  const navigate = useNavigate();

  const handleHomeNavigation = () => {
    navigate("/");
  };

  const handlePaymentsNavigation = () => {
    navigate("/Payments");
  };

  const handleTenantsNavigation = () => {
    navigate("/MyProperties/TenantsList");
  };

  const handlePropertiesNavigation = () => {
    navigate("/MyProperties");
  };

  const handleReportsNavigation = () => {
    navigate("/Reports");
  };

  return (
    <div className="h-full w-64 bg-gradient-to-b from-blue-500 to-blue-800 text-white fixed top-0 left-0">
      <div className="p-4">
        <img src={Logo} alt="Logo" className="mb-5" />
      </div>
      <div className="p-4">
        <div
          className="flex items-center cursor-pointer mb-4"
          onClick={handleHomeNavigation}
        >
          <img src={HomeIcon} alt="Home Icon" className={iconStyle} />
          Home
        </div>
        <div
          className="flex items-center cursor-pointer mb-4"
          onClick={handlePaymentsNavigation}
        >
          <img src={PaymentsIcon} alt="Payments Icon" className={iconStyle} />
          Payments
        </div>
        <div
          className="flex items-center cursor-pointer mb-4"
          onClick={handleTenantsNavigation}
        >
          <img src={TenantsIcon} alt="Tenants Icon" className={iconStyle} />
          Tenants
        </div>
        <div
          className="flex items-center cursor-pointer mb-4"
          onClick={handlePropertiesNavigation}
        >
          <img
            src={PropertiesIcon}
            alt="Properties Icon"
            className={iconStyle}
          />
          Properties
        </div>
        <div className="flex items-center cursor-pointer mb-4">
          <img src={RentIcon} alt="Rent Icon" className={iconStyle} />
          Rent
        </div>
        <div className="flex items-center cursor-pointer mb-4">
          <img
            src={AgreementsIcon}
            alt="Agreements Icon"
            className={iconStyle}
          />
          Agreements
        </div>
        <div
          className="flex items-center cursor-pointer mb-4"
          onClick={handleReportsNavigation}
        >
          <img src={ReportsIcon} alt="Reports Icon" className={iconStyle} />
          Reports
        </div>
        <div className="flex items-center cursor-pointer mb-4">
          <img src={SettingsIcon} alt="Settings Icon" className={iconStyle} />
          Settings
        </div>
      </div>
      <div className="p-4 mt-auto">
        <div className="flex items-center cursor-pointer">Accounts</div>
      </div>
      <footer className=" absolute bottom-0 p-4 mt-auto w-full text-center">
        © 2024 RentPe. All rights reserved.
      </footer>
    </div>
  );
}
