import React, { useEffect, useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CircularMeter from "./CircularMeter";
import "./rentincome.css";
import APICall from "../../Utility/APICall";
import { useAuth } from "../../Utility/AuthProvider";
import { paths } from "../../Utility/Constants";

export default function RentIncome() {
  const { jwt } = useAuth();
  const [data, setData] = useState([]);
  const [chartCounter, setChartCounter] = useState(0);
  const [previousMonthData, setPreviousMonthData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall(
          "GET",
          paths.rent.overview,
          undefined,
          undefined,
          jwt
        );
        const responseData = response.response.rent_overview;
        setData(responseData);
        console.log("Total Rent:", responseData);

        // Fetch previous month's data
        const previousMonthResponse = await APICall(
          "GET",
          paths.rent.previousMonthOverview,
          undefined,
          undefined,
          jwt
        );
        const previousMonthData = previousMonthResponse.response.rent_overview;
        setPreviousMonthData(previousMonthData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function handleOnPressNext() {
    if (chartCounter === data.length - 1) return;
    setChartCounter(chartCounter + 1);
  }

  function handleOnPressPrev() {
    if (chartCounter === 0) return;
    setChartCounter(chartCounter - 1);
  }

  function calculatePercentageChange(currentRent, previousRent) {
    if (previousRent === 0) return "--";
    const percentageChange = ((currentRent - previousRent) / previousRent) * 100;
    return percentageChange.toFixed(2) + "%";
  }

  return (
    <div className="rent-income-container border border-gray-100">
      {data && data[chartCounter] && (
        <>
          <div className="flex flex-col justify-between">
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>Monthly Rent {data[chartCounter].short_title}</p>

            <p>₹{data[chartCounter].total_rent}</p>
            <div className="calculator flex items-center">
              <ArrowOutwardIcon sx={{ color: "green" }} />
              <p style={{ color: "green" }}>
                {calculatePercentageChange(
                  data[chartCounter].total_rent,
                  previousMonthData && previousMonthData.total_rent
                )}
              </p>
              <p>vs {previousMonthData && previousMonthData.total_rent} prev month</p>
            </div>
            <div
              style={{
                height: 45,
                width: 423,
                display: "flex",
                justifyContent: "space-between",
                alignItems:'center',
                gap:40,
              }}
            >
              <div>
                <p>Received</p>
                ₹{data[chartCounter].received_rent}
              </div>
              <div>
                <p>Due</p>
                ₹{data[chartCounter].total_rent - data[chartCounter].received_rent}
              </div>
              <div>
                <p>Units Paid</p>
                {data[chartCounter].tenants_paid}
              </div>
              <div>
                <p>Units Due</p>
                {data[chartCounter].total_tenants - data[chartCounter].tenants_paid}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <button
                style={{ color: chartCounter === 0 ? "grey" : "blue" }}
                onClick={handleOnPressPrev}
              >
                Prev
              </button>
              <button
                style={{
                  color: chartCounter === data.length - 1 ? "grey" : "blue",
                }}
                onClick={handleOnPressNext}
              >
                Next
              </button>
            </div>

            <CircularMeter progress={10} leftProgress={90} />
          </div>
        </>
      )}
    </div>
  );
}
