// src/components/MonthIcons.js
import React from 'react';

// Import SVG icons for each month
import JanuaryIcon from '../assets/month/jan.png';
import FebruaryIcon from '../assets/month/feb.png';
import MarchIcon from '../assets/month/mar.png';
import AprilIcon from '../assets/month/apr.png';
import MayIcon from '../assets/month/may.png';
import JuneIcon from '../assets/month/jun.png';
import JulyIcon from '../assets/month/jul.png';
import AugustIcon from '../assets/month/aug.png';
import SeptemberIcon from '../assets/month/sep.png';
import OctoberIcon from '../assets/month/oct.png';
import NovemberIcon from '../assets/month/nov.png';
import DecemberIcon from '../assets/month/dec.png';

// Import other month icons...

const monthIcons = {
  January: JanuaryIcon,
  February: FebruaryIcon,
  March: MarchIcon,
  April: AprilIcon,
  May: MayIcon,
  June: JuneIcon,
  July: JulyIcon,
  August: AugustIcon,
  September: SeptemberIcon,
  October: OctoberIcon,
  November: NovemberIcon,
  December: DecemberIcon,
};

const MonthIcons = ({ month }) => {
  const Icon = monthIcons[month] || null;

  return Icon ? <img src={Icon} alt={`${month} icon`} className="w-6 h-6" /> : null;
};

export default MonthIcons;
