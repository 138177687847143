import React, { useState } from "react";
import CustomButton from "../Buttons/CustomButton"; // Import your CustomButton
import CustomImage from "../../Utility/CustomImage";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageModal from "./ImageModal";
import { getOrdinalSuffix } from "../../Utility/Utils";
import {RenderDocumentView} from "../../Utility/Utils"

const TenantDetailsModal = ({ isOpen, onClose, unitDetails, jwt }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageLocation) => {
    setSelectedImage(imageLocation);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-h-[90vh] overflow-y-auto relative">
        <h2 className="text-xl font-bold mb-4 text-center">
          Tenant Information
        </h2>
        <div className="mb-4 flex gap-4">
          <CustomImage
            location={unitDetails.tenant.media.profile_picture?.location}
            jwt={jwt}
            className="h-20 w-20 rounded-full"
          />
          <div>
            <p>{unitDetails.tenant.first_name}</p>
            <p>{unitDetails.tenant.mobile}</p>
          </div>
        </div>
        <div className="accordian-container">
          <Accordion className="agreement-accordian">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="agreement-accordian"
              className="text-sm"
            >
              Agreement Details
            </AccordionSummary>
            <AccordionDetails className="flex flex-col justify-between gap-2">
              <div>
                <p>{unitDetails.unit.unit_name}</p>
                <p>{unitDetails.unit.building_name}</p>
              </div>
              <div>
                <p>Monthly Rent</p>
                <p>{unitDetails.unit.localRent}</p>
              </div>
              <div>
                <p>Due Date</p>
                <p>{getOrdinalSuffix(unitDetails.agreement.due_date)} of every month</p>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="other-tenants-accordian">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="other-tenants-accordian"
              className="text-sm"
            >
              Other Tenants
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>

          <Accordion className="documents-accordian">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="documents-accordian"
              className="text-sm"
            >
              Documents
            </AccordionSummary>
            <AccordionDetails className="flex flex-col justify-between gap-2">
              {unitDetails.tenant.media.aadhar_front && (
                <a
                  onClick={() =>
                    handleImageClick(unitDetails.tenant.media.aadhar_front.location)
                  }
                >
                  View Aadhar Front
                </a>
              )}
              {unitDetails.tenant.media.aadhar_back && (
                <a
                  onClick={() =>
                    handleImageClick(unitDetails.tenant.media.aadhar_back.location)
                  }
                >
                  View Aadhar Back
                </a>
              )}
              {unitDetails.tenant.media.pan && (
                <a
                  onClick={() =>
                    handleImageClick(unitDetails.tenant.media.pan.location)
                  }
                >
                  View PAN Card
                </a>
              )}
              {unitDetails.tenant.media.rent_agreement && (
                <a
                  onClick={() =>
                    handleImageClick(unitDetails.tenant.media.rent_agreement.location)
                  }
                >
                  View Rent Agreement
                </a>
              )}
              {unitDetails.tenant.media.profile_picture && (
                <a
                  onClick={() =>
                    handleImageClick(unitDetails.tenant.media.profile_picture.location)
                  }
                >
                  View Profile Picture
                </a>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="flex justify-end mt-4">
          <CustomButton
            tailwindClasses="bg-blue-500 text-white"
            label="Close"
            onClick={onClose}
          />
        </div>
        <ImageModal isOpen={!!selectedImage} onClose={handleCloseModal}>
          <CustomImage
            location={selectedImage}
            jwt={jwt}
            className="w-60 h-60"
          />
        </ImageModal>
      </div>
    </div>
  );
};

export default TenantDetailsModal;
