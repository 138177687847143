import React, { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initialize with null to indicate no user data

  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUserJson = localStorage.getItem('userData');
    // console.log("Stored User JSON from localStorage:", storedUserJson); // Debugging

    if (storedUserJson) {
      try {
        // Parse JSON string back into an object
        const storedUser = JSON.parse(storedUserJson);
        // console.log("Parsed User Object:", storedUser); // Debugging
        setUser(storedUser);
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
      }
    }
  }, []);

  const storeUser = (user) => {
    try {
      // Convert user object to a JSON string
      const userJson = JSON.stringify(user);
      setUser(user);
      localStorage.setItem('userData', userJson);
      console.log("Stored User:", user); // Debugging
    } catch (error) {
      console.error('Error storing user data:', error);
    }
  };

  const deleteUser = () => {
    setUser(null);
    localStorage.removeItem('userData');
  };

  // console.log("Current User State:", user); // Debugging

  return (
    <UserContext.Provider value={{ user, storeUser, deleteUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  return useContext(UserContext);
};

export { UserProvider, useUser };
