import React, { useState, useEffect } from "react";
import "./unitmodal.css";  // Make sure to create a corresponding CSS file for styling
import { IoIosCloseCircleOutline } from "react-icons/io";

function UnitModal({ show, handleClose, units, selectedUnitId, handleApply }) {
  const [localSelectedUnitId, setLocalSelectedUnitId] = useState(selectedUnitId);

  useEffect(() => {
    setLocalSelectedUnitId(selectedUnitId);
  }, [selectedUnitId]);

  if (!show) {
    return null;
  }

  const handleUnitChange = (unitId) => {
    setLocalSelectedUnitId(unitId);
  };

  const handleApplyClick = () => {
    handleApply(localSelectedUnitId);
    handleClose();
  };

  return (
    <div className="modal-unit">
      <div className="modal-content-unit">
        <IoIosCloseCircleOutline
          style={{ float: "right", cursor: "pointer" }}
          onClick={handleClose}
          size={30}

        />
        <h2>Select a Unit</h2>
        <ul>
          {units.map((unit) => (
            <li key={unit.entity_id}>
              <label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    gap: "5px",
                    padding: "10px",
                  }}
                >
                  <div>
                    <p style={{ fontSize: "15px" }}>{unit.unit_name}</p>
                    <p style={{ fontSize: "10px" }}>{unit.address}</p>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="unit"
                      value={unit.entity_id}
                      checked={localSelectedUnitId === unit.entity_id}
                      onChange={() => handleUnitChange(unit.entity_id)}
                    />
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
        <button onClick={handleApplyClick} disabled={!localSelectedUnitId}>
          Apply
        </button>
      </div>
    </div>
  );
}

export default UnitModal;
