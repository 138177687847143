import React, { useState, useRef } from "react";
import "./paidmodal.css";
import { IoAddSharp, IoClose } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";

const PaidModal = ({
  onClose,
  onMarkAsPaid,
  selectedTenant,
  setSelectedPaymentType,
  setSelectedFile,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const fileInputRef = useRef(null);

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    setSelectedPaymentType(method); // Update the selected payment type in the parent component
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // Check if the selected file is of JPG or PNG format
    const acceptedFormats = ["image/jpeg", "image/png"];
    if (selectedFile && acceptedFormats.includes(selectedFile.type)) {
      setFile(selectedFile);
      setSelectedFile(selectedFile); // Update the selected file in the parent component

      // Read the file and set the image preview
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert("Please select a valid file format (JPG or PNG).");
    }
  };

  const handleClearFile = () => {
    setFile(null);
    setImagePreview(null); // Clear image preview
    setSelectedFile(null); // Clear the selected file in the parent component
  };

  const handleSubmit = () => {
    if (!selectedPaymentMethod) {
      alert("Please select a payment method.");
      return; // Prevent marking payment as paid if payment method is missing
    }
    onMarkAsPaid(); // Call the markAsPaid function from the parent component
    // Reset state and close the modal afterward
    setFile(null);
    setSelectedPaymentMethod(null);
    onClose();
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const paymentMethods = ["UPI", "Cash", "Cheque", "Bank Transfer", "Others"];

  return (
    <div className="modal-overlay">
      <div className="modal">
        <IoCloseCircleOutline style={{float:'right'}} onClick={onClose}/>
        <div className="modal-content">
          <h2 style={{ fontSize: "30px", textAlign: "center" }}>
            Mark as Paid
          </h2>
          <div className="payment-methods">
            <h3 className="pt-2 text-center">Select Payment Method</h3>
            <div id="payment-type-container">
              {paymentMethods.map((method) => (
                <button
                  key={method}
                  type="button"
                  onClick={() => handlePaymentMethodChange(method)}
                  style={{
                    border: "1px solid #000",
                    padding: "10px 20px",
                    cursor: "pointer",
                    background:
                      selectedPaymentMethod === method ? "blue" : "#fff",
                    color: selectedPaymentMethod === method ? "#fff" : "#000",
                    borderRadius: "5px",
                    outline: "none",
                    margin: "5px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {method}
                </button>
              ))}
            </div>
          </div>
          <div className="file-upload">
            <div className="description-section">
              <h3 className="p-4 text-center text-blue">+Add Description</h3>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add your description here..."
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #000",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className="file-input-container">
              {!file && (
                <div
                  className="flex flex-col items-center p-4 gap-2"
                  onClick={openFileInput}
                >
                  <IoAddSharp />
                  <p>Upload Receipt&&</p>
                  <p>Supported file: PNG, JPG</p>
                  <p style={{ textDecoration: 'underline', color: '#007bff', cursor: 'pointer' }}>Click here to browse</p>
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {imagePreview && (
                <div className="file-preview">
                  <img src={imagePreview} alt="Preview" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                  <IoClose className="clear-file" onClick={handleClearFile} />
                </div>
              )}
            </div>
          </div>
          <button id="submit-paid" onClick={handleSubmit}>
            Submit Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaidModal;
