import React, { useState, useEffect } from "react";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomImage from "../../Utility/CustomImage";

const CustomModalEditUnit = ({
  isOpen,
  onClose,
  unitDetails,
  buildings,
  onUpdate,
}) => {
  const { jwt } = useAuth();
  const [unitName, setUnitName] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [unitArea, setUnitArea] = useState("");
  const [furnishingStatus, setFurnishingStatus] = useState("furnished");
  const [bhk, setBhk] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [newAmenity, setNewAmenity] = useState("");
  const [allAmenities, setAllAmenities] = useState([
    "WiFi",
    "CCTV",
    "Television",
    "Parking",
    "Refrigerator",
    "Laundry",
    "Security",
    "Inverter",
  ]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (unitDetails) {
      setUnitName(unitDetails.unit.unit_name || "");
      setSelectedBuilding(unitDetails.unit.building_id || "");
      setUnitArea(unitDetails.unit.unit_area || "");
      setFurnishingStatus(unitDetails.unit.furnishing_status || "furnished");
      setBhk(unitDetails.unit.bhk || "");
      setAmenities(
        unitDetails.unit.amenities ? unitDetails.unit.amenities.split(",") : []
      );
    }
  }, [unitDetails]);

  const handleAddAmenity = () => {
    if (newAmenity && !amenities.includes(newAmenity)) {
      setAmenities([...amenities, newAmenity]);
      if (!allAmenities.includes(newAmenity)) {
        setAllAmenities([...allAmenities, newAmenity]);
      }
      setNewAmenity("");
    }
  };

  const handleUpdateUnit = async () => {
    const body = new FormData();
    body.append("unit_id", unitDetails.unit.entity_id); // Use entity_id for updating the unit
    body.append("unit_name", unitName);
    body.append("building_id", selectedBuilding);
    body.append("unit_area", unitArea);
    body.append("furnishing_status", furnishingStatus);
    body.append("bhk", bhk);
    body.append("amenities", amenities.join(","));

    // console.log("Request Body:");
    // for (var pair of body.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    try {
      // console.log("Updating unit with data:", Object.fromEntries(body));
      setIsLoading(true);
      const res = await APICall("PUT", paths.unit.update, body, undefined, jwt);

      if (res.status === "success") {
        // console.log("Updated Successfully", res);
        onUpdate(); // Callback to refresh or update the unit data
        onClose(); // Close the modal after update
      } else {
        console.error("Failed to update unit:", res.message);
      }
    } catch (error) {
      console.error("Error updating unit:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    return nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-h-[90vh] overflow-y-auto relative">
        {/* <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          <IoIosCloseCircleOutline size={30} />
        </button> */}
        <h2 className="text-xl font-bold mb-4 text-center">Update Unit</h2>
        <form>
          <div className="flex justify-center items-center">
            <CustomImage
              location={unitDetails.unit.media[0].location}
              jwt={jwt}
              initials={getInitials(unitDetails.unit.entity_id)}
              className="w-40 h-40"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Unit Name</label>
            <input
              type="text"
              value={unitName}
              onChange={(e) => setUnitName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Building</label>
            <select
              value={selectedBuilding}
              onChange={(e) => setSelectedBuilding(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Building</option>
              {buildings.map((building) => (
                <option key={building.entity_id} value={building.entity_id}>
                  {building.building_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Unit Area</label>
            <input
              type="text"
              value={unitArea}
              onChange={(e) => setUnitArea(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Furnishing Status
            </label>
            <select
              value={furnishingStatus}
              onChange={(e) => setFurnishingStatus(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="furnished">Furnished</option>
              <option value="semi-furnished">Semi-Furnished</option>
              <option value="non-furnished">Non-Furnished</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">BHK</label>
            <input
              type="text"
              value={bhk}
              onChange={(e) => setBhk(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Amenities</label>
            <div className="flex flex-wrap gap-2 mb-2">
              {allAmenities.map((amenity) => (
                <button
                  key={amenity}
                  type="button"
                  className={`px-3 py-1 border rounded-lg ${
                    amenities.includes(amenity)
                      ? "bg-blue-700 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => {
                    setAmenities((prev) =>
                      prev.includes(amenity)
                        ? prev.filter((item) => item !== amenity)
                        : [...prev, amenity]
                    );
                  }}
                >
                  {amenity}
                </button>
              ))}
            </div>
            <input
              type="text"
              value={newAmenity}
              onChange={(e) => setNewAmenity(e.target.value)}
              placeholder="Add new amenity"
              className="w-full p-2 border border-gray-300 rounded mb-2"
            />
            <button
              type="button"
              onClick={handleAddAmenity}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Amenity
            </button>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleUpdateUnit}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Update Unit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomModalEditUnit;
