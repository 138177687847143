// src/components/FileUpload.js
import React, { useState } from 'react';
// import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {storage} from './firebase'
import { GetUploadMediaBaseLocation } from './APICall';


const FileUpload = ({onFileUpload }) => {
  const [file, setFile] = useState(null);
  // const [progress, setProgress] = useState(0);
  // const [downloadURL, setDownloadURL] = useState('');
  // const { user, storeUser, deleteUser } = useUser();

  const handleFileChange = (e) => {
    // setFile(e.target.files[0]);
    setFile(e.target.files[0]);
    onFileUpload(e.target.files[0]);
  };

  // const handleUpload = () => {
  //   if (!file) return;

  //   const storageRef = ref(storage, `files/${file.name}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   console.log("handle Upload Function runs")

  //   uploadTask.on('state_changed', 
  //     (snapshot) => {
  //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       setProgress(progress);
  //       console.log('Upload is ' + progress + '% done');
  //     }, 
  //     (error) => {
  //       console.error('Upload failed:', error);
  //     }, 
  //     () => {
  //       const downloadURL = GetUploadMediaBaseLocation(fileLocation, user);
  //       console.log('File available at', downloadURL);
  //       onFileUpload(downloadURL)
  //     }
  //   );
  // };

  return (
    <div>
    <h2>File Upload</h2>
    <input type="file" onChange={handleFileChange} />
    {file && <img src={URL.createObjectURL(file)} alt="Preview" />}
    <div>
      <p>File uploaded successfully.</p>
    </div>
  </div>
  );
};

export default FileUpload;
