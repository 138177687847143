import React, { useState } from 'react';
import { Button, TextField, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import OTP from './OTP';
import { red } from '@mui/material/colors';
import SignUpHome from './assets/SignUpHomeImage.png'
import Logo from "./assets/Group 1261153494.png";
import "./addinfo.css"
import { useLocation, useNavigate } from 'react-router-dom';
import APICall from './Utility/APICall';
import { paths } from './Utility/Constants';
import { useUser } from './Utility/UserProvider';


export default function AddInfo() {

    const location = useLocation();
    const navigate = useNavigate();
    const { storeUser } = useUser();


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // const [accessToken, setAccessToken] = useState(''); // Set the access token


    // const user = location.state ? location.state.user : {};
    const token = location.state ? location.state.token : ""


    const validateInput = (input) => {
        // Define a regular expression pattern for allowed characters
        const pattern = /^[a-zA-Z\s]*$/;
        return pattern.test(input);
    };


    const handleUpdateUserInfo = async () => {
        const apiUrl = `https://renown-backend-assignment.el.r.appspot.com/user/update`;

        const body = {
            first_name: firstName,
            last_name: lastName,
        };
        const requestBody = body instanceof FormData ? body : new FormData();
        if (typeof body === 'object' && !(body instanceof FormData)) {
            for (const key in body) {
                if (Object.hasOwnProperty.call(body, key)) {
                    requestBody.append(key, body[key]);
                }
            }
        }

        try {
            const response = await APICall('PUT', paths.user.update, requestBody, undefined, "Bearer " + token)
            if (response.status == 'success') {
                storeUser(response.response);
                console.log('User data updated successfully:', response.response.entity_id);
                navigate('/', { state: { token: token } });
            }

        } catch (error) {
            // Handle network or other errors
            console.error('Error:', error.message);
        }
    };

    return (
        <div className='flex' style={{ height: '100vh' }}>

            <div className='part1'>

                <div className='part1-img-container'>
                    <img src={SignUpHome} height={121} width={260} />

                </div>
                <div>

                    <p className='px-10' style={{
                        fontSize: 40,
                        fontWeight: 700,
                        color: 'white',
                    }}><strong>Rent</strong> management made <strong>easy</strong> on <strong>RentPe</strong></p>
                </div>
            </div>

            <div className='pl-24' style={{ flex: .8 }}>
                <div className='logo-signup-login pt-36 pb-12'>
                    <img src={Logo} alt="Logo" width={130} height={130} />
                </div>
                <p style={{ fontSize: 24, fontWeight: 600, }}>Add your Info</p>
                <div className='flex flex-col w-96 py-10 gap-10'>
                    <TextField
                        id="first-name"
                        label="First Name"
                        variant="outlined"
                        error={!validateInput(firstName)}
                        helperText={!validateInput(firstName) ? 'Invalid input' : ''}
                        InputProps={{
                            inputProps: {
                                pattern: '^[A-Z][a-zA-Z\\s]*$',
                            },
                        }}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        id="last-name"
                        label="Last Name"
                        variant="outlined"
                        error={!validateInput(lastName)}
                        helperText={!validateInput(lastName) ? 'Invalid input' : ''}
                        inputProps={{
                            pattern: '^[A-Za-z\\s]*$',
                        }}
                        onChange={(e) => setLastName(e.target.value)}
                    />

                </div>
                <p className='pb-10 w-96 text-xs'>By selecting Confirm and Continue, I agree to RentPe Terms of Services and acknowledge the Privacy Policy.</p>
                <Button className='w-96 h-12' variant="contained" onClick={handleUpdateUserInfo}>Confirm and Continue</Button>
            </div>

        </div>
    );
}
