import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RentIncome from './components/RentIncome/RentIncome';
import Trends from '../src/components/Trends/Trends';
import UpcomingRent from './components/UpcomingRent/UpcomingRent';
import Properties from './components/Properties/Properties';
import Tenants from './components/Tenants/Tenants';
import { paths } from './Utility/Constants';
import APICall from './Utility/APICall';
import { useAuth } from './Utility/AuthProvider';
import CustomComponent from './components/RecentPayments/CustomComponent.jsx';

function Dashboard() {
    const { jwt } = useAuth();
    const navigate = useNavigate();
    const [rentData, setRentData] = useState([]);
  

    function handleClick() {
        navigate("/Payments")
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await APICall(
                    "GET",
                    paths.rent.overview,
                    undefined,
                    undefined,
                    jwt
                );
                console.log("Response:", response);
                const rentDataFromResponse = response?.response?.rent_data || [];
                setRentData(rentDataFromResponse);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
        return () => {
        };
    }, [jwt]); 

    const handleViewAllUpcomingRent = () => {
        navigate('/ViewAll', { state: { rentData } });
    };

    return (
            <div>
                <div className='text-xs'>
                    <p className="text-xl font-black">Home</p>
                    <div className='flex justify-between px-10 py-5 gap-10'>
                        <div>
                            <div className='flex justify-between items-center px-4 py-5'>
                                <p>Rent Income</p>
                                <p>View Rent Collection</p>
                            </div>
                            <RentIncome />
                        </div>
                        <div className='overflow-hidden'>
                            <div className='flex justify-between items-center px-4 py-5'>
                                <p>Trends</p>
                            </div>
                            <Trends />
                        </div>
                    </div>
                    <div className='flex justify-between px-10'>
                        <div style={{ marginRight: '20px' }}>
                            <div className='flex justify-between items-center px-4 py-5'>
                                <p>Recent Payments</p>
                                <p onClick={handleClick}>View All</p>
                            </div>
                            <CustomComponent displayAll={false} />
                        </div>
                        <div>
                            <div className='flex justify-between items-center px-4 py-5'>
                                <p>Upcoming Rent</p>
                                <p
                                    style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={handleViewAllUpcomingRent}
                                >View All</p> {/* Make the View All clickable */}
                            </div>
                            <UpcomingRent />
                        </div>
                    </div>
                    <div className='flex justify-between px-10 py-5'>
                        <div>
                            <div className='flex justify-between items-center px-4 py-5'>
                                <p>Tenants</p>
                                <p>View All</p>
                            </div>
                            <Tenants />
                        </div>
                        <div>
                            <div className='flex justify-between items-center px-4 py-5'>
                                <p>Properties</p>
                                <p>View All</p>
                            </div>
                            <Properties />
                        </div>
                    </div>
                </div>
            </div>
        // </Layout>
    );
}

export default Dashboard;
