import React from "react";
import "./vacantbtn.css";

function VacantBtn({children,className}) {
  return (
    <div>
      <button className={`vacant-btn ${className}`}>
        {children}
      </button>
    </div>
  );
}

export default VacantBtn;
