export const paths = {
    user: {
        login: '/user/login',
        signup: '/user/signup',
        update: '/user/update',
        validate: '/user/validate',
        logout: '/user/logout',
        fetch: '/user/fetch',
        fetchSessions: '/user/session/fetch',
        kycStatus: '/user/kyc/status',
        kycUpdate: '/user/kyc/update',
        sendOTP: '/user/sendotp'
    },
    building: {
        add: '/building/add',
        update: '/building/update',
        delete: '/building/delete',
        fetch: '/building/fetch',
        updateCount: '/building/update/count',
    },
    unit: {
        add: '/unit/add',
        update: '/unit/update',
        delete: '/unit/delete',
        fetch: '/unit/fetch',
        view: '/unit/view',
    },
    agreement: {
        add: '/agreement/add',
        update: '/agreement/update',
        delete: '/agreement/delete',
        fetch: '/agreement/fetch',
    },
    transaction: {
        add: '/transaction/add',
        update: '/transaction/update',
        delete: '/transaction/delete',
        fetch: '/transaction/fetch',
    },
    media: {
        download: '/media/download',
    },
    tenant: {
        add: '/user/tenant/add',
        update: '/user/tenant/update',
        validate: '/user/tenant/validate',
        fetchAll: '/property/tenants',
        fetch: '/user/tenant/fetch',
    },
    rent: {
        overview: '/rent/overview',
        markaspaid: '/rent/markaspaid',
        reminder: '/rent/reminder',
    },
    payments: {
        fetch: '/payments/fetch',
    },
    property: {
        summary: '/property/summary',
    }
}

export const api_url = "https://renown-backend-assignment.el.r.appspot.com"

export const cloudStorageBucket = {
    base: 'renown-backend-assignment.appspot.com/',
    buildings: 'user-property-media/buildings/',
    units: 'user-property-media/units/',
    agreements: 'user-property-media/agreements/',
    tenant: {
        aadhar: 'user-tenant-media/aadhar-card',
        pan: 'user-tenant-media/pan-card',
        rentAgreement: 'user-tenant-media/rent-agreement',
        profilePicture: 'user-tenant-media/profile-picture',
    },
    profilePicture: 'user-profile-media/profile-picture/',
    kyc: 'user-kyc-media/',
    transaction: 'user-transaction-media/'
}

export const frontendVerificationKey = 'q5j5c0JTuI8gI3GXmkhLO5d6ND4OeoUCPBrCDpLiX6Jkou5tRiHIhFHF82p5mNfZWdipRn9rzB3v0NLwxfZoQA3br0LhixCURnfMqTkOYNeAS4C6JrYFGLTm20f72NGv';

export const currentPlatform = 'web';

export const developmentHeaderKey = "DevelopmentModeLMXH820c0820c9siaocnsA8b2x8cbn"
