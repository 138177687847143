import React, { useState, useEffect } from 'react';
import { getAuthorizationHeaders, GetMediaURL } from './APICall';
import { paths } from './Constants';
import { PiBuildingsLight } from "react-icons/pi";

function CustomImage({ location, jwt, className }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const mediaURL = GetMediaURL(paths.media.download, location, { quality: 20 });
        const headers = getAuthorizationHeaders(jwt);

        const response = await fetch(mediaURL, {
          method: "GET",
          headers: headers
        });

        if (!response.ok) throw new Error('Network response was not ok');

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
      } catch (error) {
        console.error('Error fetching the image:', error);
      }
    };

    if (location) {
      fetchImage();
    }

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [location, jwt]);

  return (
    <div className={`flex justify-center items-center overflow-hidden ${className}`}>
      {imageUrl ? (
        <img src={imageUrl} alt="Fetched from server" className="object-cover w-full h-full" />
      ) : (
        <div className="flex justify-center items-center w-full h-full">
          <PiBuildingsLight size={50} color='grey' />
        </div>
      )}
    </div>
  );
}

export default CustomImage;
