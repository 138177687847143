import React from "react";
import { IoFilter } from "react-icons/io5";

const FilterBtn = ({ selectedFiltersCount, onClick }) => {
  return (
    <button onClick={onClick} className="relative flex items-center mr-3">
      <IoFilter className="text-gray-700" size={24} />
      {selectedFiltersCount > 0 && (
        <span className="absolute top-[-10px] right-[-10px] bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
          {selectedFiltersCount}
        </span>
      )}
    </button>
  );
};

export default FilterBtn;
