import React from "react";

const ToggleBtn = ({ label, isSelected, onClick }) => {
  return (
    <button
      className={`border text-center rounded-full w-20 text-sm  py-1 ${
        isSelected ? "border-blue-700 bg-blue-700 text-white" : "border-black border-1 text-black"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default ToggleBtn;
