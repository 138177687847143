import React, { useState, useEffect } from "react";
import "./tenantmodal.css";  // Ensure you have this CSS file
import { IoIosCloseCircleOutline } from "react-icons/io";

function TenantModal({ show, handleClose, tenantData, selectedTenantId, handleApply }) {
  const [localSelectedTenantId, setLocalSelectedTenantId] = useState(selectedTenantId);

  useEffect(() => {
    setLocalSelectedTenantId(selectedTenantId);
  }, [selectedTenantId]);

  if (!show) {
    return null;
  }

  const handleTenantChange = (tenantId) => {
    setLocalSelectedTenantId(tenantId);
  };

  const handleApplyClick = () => {
  handleApply(localSelectedTenantId);
  console.log("Tenant Apply Btn clicked",localSelectedTenantId)
  handleClose();
};

  return (
    <div className="modal-tenant">
      <div className="modal-content-tenant">
        <IoIosCloseCircleOutline
          style={{ float: "right", cursor: "pointer" }}
          onClick={handleClose}
          size={30}
        />
        <h2>Select a Tenant</h2>
        <ul>
          {tenantData.map((tenant) => (
            <li key={tenant.tenant_id}>
              <label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    gap: "5px",
                    padding: "10px",
                  }}
                >
                  <div>
                    <p style={{ fontSize: "15px" }}>{tenant.first_name}</p>
                    <p style={{ fontSize: "10px" }}>{tenant.building_name}</p>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="tenant"
                      value={tenant.tenant_id}
                      checked={localSelectedTenantId === tenant.entity_id}
                      onChange={() => handleTenantChange(tenant.entity_id)}
                    />
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
        <button onClick={handleApplyClick} disabled={false}>
          Apply
        </button>
      </div>
    </div>
  );
}

export default TenantModal;
