import React from 'react';

const Facility = ({ name, icon: Icon }) => {
  return (
    <div className="facility-1 w-20 h-20 border bg-white rounded-xl mt-5 flex justify-center items-center">
      <div className="flex flex-col items-center">
        <p>{name}</p>
        <Icon size={30} />
      </div>
    </div>
  );
};

export default Facility;
