import React, { useState } from "react";
import CustomButton from "../Buttons/CustomButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOrdinalSuffix } from "../../Utility/Utils"; // Adjust the import path as necessary
import AddTenantModal from "./AddTenantModal";
import { addMonths } from "date-fns"; // Import date-fns to handle date manipulation

const AddAgreementModal = ({
  isOpen,
  onClose,
  onAddAgreement,
  unitDetails,
}) => {
  const [monthlyRent, setMonthlyRent] = useState("");
  const [stayingFrom, setStayingFrom] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [securityDeposit, setSecurityDeposit] = useState("");
  const [maintenanceCharge, setMaintenanceCharge] = useState("");
  const [advanceRent, setAdvanceRent] = useState(false);
  const [tenure, setTenure] = useState(null);
  const [showAddTenantModal, setShowAddTenantModal] = useState(false);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [vacatingDate, setVacatingDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleTenureClick = (value) => {
    if (value === "custom") {
      setShowDatePicker(true);
      setTenure(value); // Set tenure to 'custom' to apply custom styling
    } else {
      setTenure(value);
      setShowDatePicker(false); // Hide date picker for non-custom tenures
    }
  };

  const handleDateChange = (date) => {
    setVacatingDate(date);
    setShowDatePicker(false); // Hide the date picker after selecting a date
    setTenure("custom"); // Ensure tenure is set to custom when a date is chosen
  };

  const calculateVacatingDate = () => {
    if (tenure === "custom" && vacatingDate) {
      return vacatingDate;
    }
    if (!stayingFrom || !tenure || tenure === "custom") return null;
    return addMonths(stayingFrom, tenure); // Calculate end date based on stayingFrom and tenure
  };

  const vacatingDateCalculated = calculateVacatingDate();

  const handleFormSubmit = () => {
    if (!tenantDetails) {
      alert("Please add tenant details before submitting the agreement.");
      return;
    }

    const agreementDetails = {
      monthlyRent,
      stayingFrom,
      dueDate,
      securityDeposit,
      maintenanceCharge,
      advanceRent,
      tenure,
      tenantDetails,
    };
    onAddAgreement(agreementDetails); // Submit the agreement details including tenant info
  };

  const handleAddTenant = (details) => {
    setTenantDetails(details);
    setShowAddTenantModal(false);
  };

  const toggleAdvanceRent = () => {
    setAdvanceRent((prev) => !prev);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">New Agreement</h2>
        <div>
          <p>{unitDetails?.unit.unit_name}</p>
          <p>{unitDetails?.unit.address}</p>
        </div>

        <div className="mt-4">
          <p>Agreement Details</p>
          <div className="form-data-agreement flex flex-col justify-between gap-4">
            {/* Monthly Rent Amount */}
            <div className="mt-4">
              <label
                htmlFor="monthlyRent"
                className="block text-sm font-medium text-gray-700"
              >
                Monthly Rent Amount
              </label>
              <input
                id="monthlyRent"
                type="number"
                required
                value={monthlyRent}
                onChange={(e) => setMonthlyRent(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>

            {/* Staying From Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Staying From
              </label>
              <DatePicker
                selected={stayingFrom}
                onChange={(date) => setStayingFrom(date)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>

            {/* Due Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Due Date
              </label>
              <DatePicker
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {dueDate && (
                <p className="pt-2">{`Due on the ${getOrdinalSuffix(
                  dueDate.getDate()
                )} of every month`}</p>
              )}
            </div>

            {/* Security Deposit */}
            <div>
              <label
                htmlFor="securityDeposit"
                className="block text-sm font-medium text-gray-700"
              >
                Security Deposit
              </label>
              <input
                id="securityDeposit"
                type="number"
                required
                value={securityDeposit}
                onChange={(e) => setSecurityDeposit(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>

            {/* Maintenance Charge */}
            <div>
              <label
                htmlFor="maintenanceCharge"
                className="block text-sm font-medium text-gray-700"
              >
                Maintenance Charge
              </label>
              <input
                id="maintenanceCharge"
                type="number"
                required
                value={maintenanceCharge}
                onChange={(e) => setMaintenanceCharge(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              <p className="text-xs">
                Lease does not coincide with previous agreements
              </p>
            </div>

            {/* Advance Rent Toggle */}
            <div className="text-xs flex flex-col items-start justify-between">
              <div className="w-full mt-4 flex items-center justify-between">
                <label
                  htmlFor="advanceRent"
                  className="block text-gray-700 mr-2"
                >
                  Advance Rent
                </label>
                <div
                  onClick={toggleAdvanceRent}
                  className={`w-10 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                    advanceRent ? "bg-green-500" : ""
                  }`}
                >
                  <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
                      advanceRent ? "translate-x-4" : ""
                    }`}
                  />
                </div>
              </div>
              <p className="">
                The rent will be collected in advance for the month of the
                agreement
              </p>
            </div>

            {/* Tenure Selection */}
            <div className="mt-4 grid gap-4">
              <label className="block text-sm font-medium text-gray-700 mb-2 col-span-full">
                Tenure
              </label>
              <div className="grid grid-cols-2 gap-2 col-span-full">
                {[3, 6, 11].map((months) => (
                  <button
                    key={months}
                    onClick={() => handleTenureClick(months)}
                    className={`px-4 py-2 rounded-md ${
                      tenure === months
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200"
                    }`}
                  >
                    {months} Months
                  </button>
                ))}
                <button
                  onClick={() => handleTenureClick("custom")}
                  className={`px-4 py-2 rounded-md ${
                    tenure === "custom"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  Custom
                </button>
              </div>
              {tenure && (tenure !== "custom" || vacatingDate) && (
                <p className="mt-2 col-span-full">
                  Vacating on:{" "}
                  {tenure === "custom" && vacatingDate
                    ? vacatingDate.toDateString()
                    : vacatingDateCalculated
                    ? vacatingDateCalculated.toDateString()
                    : "Not set"}
                </p>
              )}
              {tenure === "custom" && showDatePicker && (
                <DatePicker
                  selected={vacatingDate}
                  onChange={handleDateChange}
                  className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <CustomButton
            tailwindClasses="bg-blue-500 text-white"
            label="Continue to Add Tenant"
            onClick={() => setShowAddTenantModal(true)}
          />
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded"
            onClick={onClose}
          >
            Close
          </button>

          <AddTenantModal
            isOpen={showAddTenantModal}
            onClose={() => setShowAddTenantModal(false)}
            onAddTenant={handleAddTenant}
          />
        </div>
      </div>
    </div>
  );
};

export default AddAgreementModal;
