// AddTenantModal.jsx
import React, { useState } from 'react';
import CustomButton from "../Buttons/CustomButton";

const AddTenantModal = ({ isOpen, onClose, onAddTenant }) => {
  const [tenantName, setTenantName] = useState('');
  const [tenantEmail, setTenantEmail] = useState('');
  const [tenantPhone, setTenantPhone] = useState('');

  const handleFormSubmit = () => {
    const tenantDetails = {
      name: tenantName,
      email: tenantEmail,
      phone: tenantPhone,
    };
    onAddTenant(tenantDetails);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Add Tenant</h2>
        <div className="mt-4">
          <div className="form-data-tenant flex flex-col justify-between gap-4">
            <div>
              <label htmlFor="tenantName" className="block text-sm font-medium text-gray-700">Tenant Name</label>
              <input
                id="tenantName"
                type="text"
                value={tenantName}
                onChange={(e) => setTenantName(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="tenantEmail" className="block text-sm font-medium text-gray-700">Tenant Email</label>
              <input
                id="tenantEmail"
                type="email"
                value={tenantEmail}
                onChange={(e) => setTenantEmail(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="tenantPhone" className="block text-sm font-medium text-gray-700">Tenant Phone</label>
              <input
                id="tenantPhone"
                type="text"
                value={tenantPhone}
                onChange={(e) => setTenantPhone(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-4">
          <CustomButton
            tailwindClasses="bg-blue-500 text-white"
            label="Add Tenant"
            onClick={handleFormSubmit}
          />
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTenantModal;
