import React from 'react';
import { IoWalletOutline } from "react-icons/io5";

function Paid({
    isMarkAsPaidDisabled,
    onPressMarkAsPaid = () => {}
}) {
  const divStyle = {
    height: '1.5rem', // h-6
    width: '5rem', // w-20
    borderRadius: '9999px', // rounded-full
    backgroundColor: 'transparent', // bg-red-500
    borderWidth: '1px', // border-solid
    borderColor: 'blue', // border-solid
    borderStyle: 'solid', // border-solid
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap:2,
    cursor: 'pointer',
    color: 'blue', // Updated text color to blue
  };

  const textStyle = {
    color: 'blue', // Updated text color to blue
    cursor:'pointer'
  };

  return (
    <div style={divStyle}>
      <IoWalletOutline size={15}/>
      <button onClick={onPressMarkAsPaid} style={textStyle} disabled={isMarkAsPaidDisabled}>Paid</button>
    </div>
  );
}

export default Paid;
