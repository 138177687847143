import React, { useState, useEffect } from "react";
import "./filterbuildingmodal.css";
import { IoIosCloseCircleOutline } from "react-icons/io";

function FilterBuildingModal({ show, handleClose, buildings, selectedBuildingId, handleApply }) {
  const [localSelectedBuildingId, setLocalSelectedBuildingId] = useState(selectedBuildingId);

  useEffect(() => {
    setLocalSelectedBuildingId(selectedBuildingId);
  }, [selectedBuildingId]);

  if (!show) {
    return null;
  }

  const handleBuildingChange = (buildingId) => {
    setLocalSelectedBuildingId(buildingId);
  };

  const handleApplyClick = () => {
    handleApply(localSelectedBuildingId);
    handleClose();
  };

  return (
    <div className="modal-fb">
      <div className="modal-content-fb">
        <IoIosCloseCircleOutline
          style={{ float: "right" }}
          onClick={handleClose}
          size={30}

        />
        <h2>Select a Building</h2>
        <ul>
          {buildings.map((building) => (
            <li key={building.entity_id}>
              <label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    gap: "5px",
                    padding: "10px",
                  }}
                >
                  <div>
                    <p style={{ fontSize: "15px" }}>{building.building_name}</p>
                    <p style={{ fontSize: "10px" }}>{building.address}</p>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="building"
                      value={building.entity_id}
                      checked={localSelectedBuildingId === building.entity_id}
                      onChange={() => handleBuildingChange(building.entity_id)}
                    />
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
        <button onClick={handleApplyClick} disabled={!localSelectedBuildingId}>
          Apply
        </button>
      </div>
    </div>
  );
}

export default FilterBuildingModal;
