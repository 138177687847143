import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import Layout from "../Layout";
import { FaHouse } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi";
import CustomImage from "../../Utility/CustomImage";
import { IoIosAddCircle } from "react-icons/io";

function Buildings() {
  const { jwt } = useAuth();
  const [buildings, setBuildings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const buildingResponse = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );
        setBuildings(buildingResponse.response);
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };

    fetchBuildings();
  }, [jwt]);

  const handleBuildingClick = (building) => {
    navigate(`/Units?buildingId=${building.entity_id}`, { state: building });
  };

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();
    return initials;
  };

  function handleAddBuildingClick(){
    navigate('/AddNewBuilding')
  }

  return (
    // <Layout>
      <div>
        <div className="banner-container flex justify-start items-center gap-2 pb-4 pt-4">
          <p className="text-black text-2xl">Add New Building</p>
          <button onClick={handleAddBuildingClick}><IoIosAddCircle size={30} color="green"/></button>
        </div>
        <div className="building-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {buildings &&
            buildings.map((building, index) => (
              <div
                onClick={() => handleBuildingClick(building)}
                key={index}
                className="building-card p-4 border border-black rounded flex flex-col items-center gap-4 cursor-pointer"
              >
                {building.media && building.media.length > 0 ? (
                  <CustomImage
                    location={building.media[0].location}
                    jwt={jwt}
                    initials={getInitials(building.building_name)}
                  />
                ) : (
                  <div
                    className="bg-green-500 text-white text-xl flex items-center justify-center rounded-full"
                    style={{ width: "100px", height: "100px" }}
                  >
                    {getInitials(building.building_name)}
                  </div>
                )}
                <div className="text-center">
                  <p className="font-bold">{building.building_name}</p>
                  <p>{building.address}</p>
                  <div className="flex justify-center gap-2 mt-2">
                    <p className="flex items-center gap-2">
                      <FaHouse />
                      {building.number_of_flats} Units
                    </p>
                    <p className="flex items-center gap-2">
                      <HiUsers />
                      {building.no_of_tenants} Tenants
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    // </Layout>
  );
}

export default Buildings;
