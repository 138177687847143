import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { FaHouse } from "react-icons/fa6";
import { MdChair } from "react-icons/md";
import "./units.css";
import { IoIosAdd } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { PiBuildingsLight } from "react-icons/pi";
import CustomImage from "../../Utility/CustomImage";
import CircularInitial from "../UpcomingRent/CircularInitial";
import FilterBtn from "../../components/Buttons/FilterBtn";
import ToggleBtn from "../../components/Buttons/ToggleBtn";
import VacantBtn from "../../components/Buttons/VacantBtn";
import RentedBtn from "../../components/Buttons/RentedBtn";

function Units() {
  const { jwt } = useAuth();
  const [allUnits, setAllUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUnits() {
      try {
        const unitResponse = await APICall(
          "GET",
          paths.unit.fetch,
          undefined,
          undefined,
          jwt
        );
        setAllUnits(unitResponse.response);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    }
    fetchUnits();
  }, [jwt]);

  useEffect(() => {
    let filtered = allUnits.filter((unit) =>
      `${unit.unit_name} ${unit.address} ${unit.bhk} ${unit.furnishing_status}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    if (selectedFilters.includes("Vacant")) {
      filtered = filtered.filter((unit) => unit.occupancy_status === "vacant");
    }

    if (selectedFilters.includes("Rented")) {
      filtered = filtered.filter((unit) => unit.occupancy_status === "rented");
    }

    setFilteredUnits(filtered);
  }, [allUnits, searchQuery, selectedFilters]);

  const toggleFilter = (filter) => {
    setSelectedFilters((prev) =>
      prev.includes(filter)
        ? prev.filter((f) => f !== filter)
        : [...prev, filter]
    );
  };

  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  const handleAddNewUnit = () => {
    navigate("/AddNewUnit");
  };

  const handleEditUnitClick = (unit) => {
    console.log("Editing unit:", unit); // Log the unit details
    if (!unit || !unit.unit_name) {
      console.error("Invalid unit details:", unit); // Log any issues with unit details
      return;
    }
    navigate(`/MyProperties/${unit.unit_name}`, { state: unit });
  };

  return (
    <div className="border-2 p-10 rounded-2xl">
      <div className="flex items-center justify-between">
        <p className="font-black">My Units</p>
        <button
          onClick={handleAddNewUnit}
          className="flex items-center bg-blue-700 text-white text-xs p-3 rounded-full"
        >
          <IoIosAdd size={15} />
          Add New
        </button>
      </div>

      <div className="flex items-center border-gray border mt-4 p-2 rounded-full gap-2">
        <CiSearch size={20} />
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="flex-grow outline-none bg-transparent"
        />
      </div>

      <div className="flex items-center mt-4 gap-2">
        <FilterBtn
          selectedFiltersCount={selectedFilters.length}
          onClick={handleClearFilters}
        />
        <ToggleBtn
          label="Vacant"
          isSelected={selectedFilters.includes("Vacant")}
          onClick={() => toggleFilter("Vacant")}
        />
        <ToggleBtn
          label="Rented"
          isSelected={selectedFilters.includes("Rented")}
          onClick={() => toggleFilter("Rented")}
        />

        <ToggleBtn
          label="Active"
          isSelected={selectedFilters.includes("Active")}
          onClick={() => toggleFilter("Active")}
        />

        <ToggleBtn
          label="Inactive"
          isSelected={selectedFilters.includes("Inactive")}
          onClick={() => toggleFilter("Inactive")}
        />
      </div>

      <div className="wrapper mt-4">
        {filteredUnits.map((unit) => (
          <div
            key={unit.unit_id}
            className="unit-item-container flex justify-between mb-4 border-2 p-4 rounded-2xl"
          >
            <div className="flex justify-between w-1/3">
              <div className="rounded-xl overflow-hidden w-60 h-60 flex-shrink-0">
                <CustomImage
                  location={
                    unit.media && unit.media.length > 0
                      ? unit.media[0].location
                      : null
                  }
                  jwt={jwt}
                  className="w-60 h-60"
                />
              </div>

              <div className="unit-data-container text-xs ml-4 p-2 rounded-lg flex flex-col justify-between flex-grow">
                <div>
                  <p className="text-black">{unit.unit_name}</p>
                  <p className="text-black mb-2">{unit.address}</p>
                  {unit.occupancy_status === "vacant" ? (
                    <VacantBtn>Vacant</VacantBtn>
                  ) : (
                    <RentedBtn>Rented</RentedBtn>
                  )}
                </div>

                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-2">
                    <FaHouse />
                    <p>{unit.bhk.split(" ")[0]}BHK</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <MdChair />
                    <p className="text-nowrap">{unit.furnishing_status}</p>
                  </div>
                  <div>{unit.status}</div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center float-end">
              <button
                onClick={() => handleEditUnitClick(unit)}
                className="bg-blue-700 text-white text-xs px-4 py-2 rounded-full"
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Units;

// <div>
//   <div className="banner-container flex justify-start items-center gap-2 mb-4 ml-2 pl-2">
//     <button
//       style={{
//         border: "1px",
//         borderStyle: "solid",
//         padding: "4px",
//         borderRadius: "10px",
//         display: "flex",
//         alignItems: "center",
//         gap: "2px",
//         textAlign: "center",
//         color: selectedBuilding ? "blue" : "grey",
//         borderColor: selectedBuilding ? "blue" : "grey",
//       }}
//       type="dropdown"
//       onClick={() => setShowModal(true)}
//     >
//       Buildings <IoIosArrowDropdown />
//     </button>
//     <FilterBuildingModal
//       show={showModal}
//       handleClose={() => setShowModal(false)}
//       buildings={buildings}
//       handleApply={handleApplyBuildingSelect}
//     />

//     <button
//       style={{
//         border: "1px",
//         borderStyle: "solid",
//         padding: "4px",
//         borderRadius: "10px",
//         display: "flex",
//         alignItems: "center",
//         gap: "2px",
//         textAlign: "center",
//         color: showVacant ? "blue" : "grey",
//         borderColor: showVacant ? "blue" : "grey",
//       }}
//       onClick={handleVacantFilterToggle}
//     >
//       {showVacant ? (
//         <>
//           Vacant <IoIosCloseCircle color="blue" />
//         </>
//       ) : (
//         "Vacant"
//       )}
//     </button>

//     <button
//       style={{
//         border: "1px solid red",
//         padding: "4px",
//         borderRadius: "10px",
//         display: "flex",
//         alignItems: "center",
//         gap: "2px",
//         textAlign: "center",
//         color: "red",
//       }}
//       onClick={() => {
//         setSelectedBuilding(null);
//         setShowVacant(false);
//         setFilteredUnits(allUnits);
//       }}
//     >
//       Clear Filters
//     </button>
//   </div>

//   <div className="units-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//     {filteredUnits.length > 0 ? (
//       filteredUnits.map((unit) => (
//         <div
//           onClick={() => handleEditUnitClick(unit)}
//           key={unit.entity_id}
//           className="unit-details p-4 border border-black rounded flex flex-col items-start gap-4 cursor-pointer"
//         >
//           <div className="flex justify-start items-center gap-2 w-full">
//             {unit.media && unit.media.length > 0 ? (
//               <CustomImage
//                 location={unit.media[0].location}
//                 jwt={jwt}
//                 initials={getInitials(unit.building_name)}
//               />
//             ) : (
//               <div
//                 className="unit-initials bg-green-500 text-white text-xl flex items-center justify-center rounded-full"
//                 style={{ width: "100px", height: "100px" }}
//               >
//                 {getInitials(unit.unit_name)}
//               </div>
//             )}
//             <div style={{ fontSize: "16px", flex: 1 }}>
//               <div className="flex items-center justify-between">
//                 <div className="flex items-center">
//                   <p>{unit.unit_name}</p>
//                   <p>
//                     {unit.occupancy_status === "rented" ? (
//                       <RentedBtn>
//                         <p>Rented</p>
//                       </RentedBtn>
//                     ) : (
//                       <VacantBtn>
//                         <p>Vacant</p>
//                       </VacantBtn>
//                     )}
//                   </p>
//                 </div>
//                 <div className="">
//                   <p>₹{unit.rent}</p>
//                 </div>
//               </div>
//               <p>{unit.building_name}</p>
//               <p>{unit.address}</p>
//               <div className="flex items-center gap-2">
//                 <div className="flex items-center gap-2">
//                   <FaHouse />
//                   <p>{unit.bhk} BHK</p>
//                 </div>
//                 <div className="flex items-center gap-2">
//                   <MdChair />
//                   <p>{unit.furnishing_status}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))
//     ) : (
//       <p>No Units</p>
//     )}

//     {/* Add New Unit Button */}
//     <div
//       className="unit-details add-new-unit-btn p-4 border border-black rounded flex items-center justify-center cursor-pointer"
//       onClick={handleAddNewUnit}
//     >
//       <CiSquarePlus size={100} color="green" />
//     </div>
//   </div>
// </div>
// </Layout>
