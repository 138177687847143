import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SignUpHome from "./assets/SignUpHomeImage.png";
import Logo from "./assets/Group 1261153494.png";
import "./loginform.css";
import OtpInput from "react-otp-input";
import APICall from "./Utility/APICall";
import { paths } from "./Utility/Constants";
import { useAuth } from "./Utility/AuthProvider";
import ContinueBtn from "./components/ContinueBtn";
import { useUser } from "./Utility/UserProvider";

export default function LoginForm() {
  const location = useLocation();
  const { storeJwt } = useAuth();
  const { storeUser } = useUser();

  // Access the passed data using location.state
  const receivedNumber = location.state ? location.state.number : "0000000000";
  const isNewUser = location.state ? location.state.isNewUser : true;

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    sendOTP();
  }, []); // Ensure this useEffect has an empty dependency array to call sendOTP only once

  const sendOTP = async () => {
    try {
      const formData = {
        mobile: receivedNumber,
      };
      const result = await APICall("POST", paths.user.sendOTP, formData);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleContinue = async () => {
    const formData = {
      mobile: receivedNumber,
      otp: otp,
      device_meta: JSON.stringify({}),
    };
    const result = await APICall("POST", paths.user.login, formData)

    console.log("signup api call result: ", result);

    if (result.status === "success") {
      console.log("User logged in successfully");
      const token = result.response.token;
      const user = result.response.user;
      console.log("user details: ", user);

      if (isNewUser) {
        const formData = new FormData();
        formData.append("mobile_verified", true);
        const res = await APICall(
          "PUT",
          paths.user.update,
          formData,
          undefined,
          "Bearer " + token
        );
        console.log(res);
        console.log("Updated mobile verified to true");
      }

      if (!result.response.user.first_name || !result.response.user.last_name) {
        console.log("Navigating to AddInfo");
        storeJwt("Bearer " + token);
        navigate("/AddInfo", { state: { token: token, user: user } });
      } else {
        storeJwt("Bearer " + token);
        storeUser(user);
        console.log("JWT Token Stored: ", token)
        console.log("User Data Stored: ", user)
        navigate("/");
      }
    }
  };

  return (
    <div className="flex" style={{ height: "100vh" }}>
      <div className="part1">
        <div className="part1-img-container">
          <img src={SignUpHome} height={121} width={260} alt="SignUpHome" />
        </div>
        <div>
          <p
            className="px-10"
            style={{
              fontSize: 40,
              fontWeight: 700,
              color: "white",
            }}
          >
            <strong>Rent</strong> management made <strong>easy</strong> on{" "}
            <strong>RentPe</strong>
          </p>
        </div>
      </div>

      <div className="pl-24" style={{ flex: 0.8, marginRight: 20 }}>
        <div className="logo-signup-login pt-36 pb-12">
          <img src={Logo} alt="Logo" width={130} height={130} />
        </div>
        <div>
          <p style={{ paddingBottom: 32, fontSize: 24, fontWeight: 600 }}>
            Confirm your number {isNewUser}
          </p>
          <p className="w-96 size-4 flex">
            Enter the OTP we’ve sent by SMS to {receivedNumber}:
          </p>
        </div>
        <div className="flex my-8">
          <OtpInput
            value={otp}
            onChange={setOtp}
            containerStyle={{
              width: "500px",
              display: "flex",
              justifyContent: "start",
              gap: "20px",
            }}
            inputStyle={{
              width: "50px",
              height: "50px",
              borderColor: "grey",
              borderWidth: 2,
              borderRadius: 8,
            }}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <p className="w-96 pb-10 size-2" onClick={() => sendOTP()}>
          Haven’t received an OTP? Send again
        </p>

        <ContinueBtn onClick={handleContinue}>Verify & Continue</ContinueBtn>
      </div>
    </div>
  );
}
