import React from "react";
import Avatar from "@mui/material/Avatar";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Utility/AuthProvider";

function Navbar({}) {
  const { deleteJwt } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    deleteJwt();
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="mt-2 pb-2">
      {/* <div className="flex justify-between items-center gap-10"> */}

      <div className="flex items-center justify-end gap-4">
        <input
          type="text"
          className="border border-gray-300 rounded-md p-2"
          placeholder="Search.."
        />
        <NotificationsActiveIcon className="ml-4 mr-2" />
        <Avatar alt="User Avatar" src="path_to_avatar_image" />
        <button
          onClick={handleLogout}
          className="p-2 w-40 bg-blue-500 rounded-lg"
        >
          LogOut
        </button>
      </div>
    </div>
    // </div>
  );
}

export default Navbar;
