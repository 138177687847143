import React from 'react';

const CircularMeter = ({ progress, leftProgress }) => {
  const radius = 50;
  const strokeWidth = 20;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (progress / 100) * circumference;
  const leftProgressOffset = circumference - ((progress + leftProgress) / 100) * circumference;
  const blueCircumference = (progress / 100) * circumference;

  return (
    <div style={{ position: "relative", width: "200px", height: "200px" }}>
      <svg width="100%" height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "0", left: "0" }}>
        {/* Gray meter */}
        <circle cx="100" cy="100" r={radius} fill="none" stroke="#ccc" strokeWidth={strokeWidth} />

        {/* Gray progress */}
        <circle
          cx="100"
          cy="100"
          r={radius}
          fill="none"
          stroke="#F1EFFB"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={leftProgressOffset}
          strokeLinecap="round"
        />
      </svg>

      <svg width="100%" height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "0", left: "0" }}>
        {/* Blue progress */}
        <circle
          cx="100"
          cy="100"
          r={radius}
          fill="none"
          stroke="blue"
          strokeWidth={strokeWidth}
          strokeDasharray={blueCircumference + " " + circumference}
          strokeDashoffset={progressOffset}
          strokeLinecap="round"
        />
      </svg>

      {/* Text label */}
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "20px" }}>
        {progress}%
      </div>
    </div>
  );
};

export default CircularMeter;
