import React from "react";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppComponent = ({ selectedTenant, label = "Remind" }) => {
  const history = useNavigate();

  const sendMessageToWhatsApp = () => {
    if (!selectedTenant.mobile) {
      console.error("Mobile number not provided");
      return;
    }

    if (!selectedTenant.hasWhatsApp) {
      console.error("WhatsApp not available for this contact");
      const inviteLink = `https://wa.me/${selectedTenant.mobile}`;
      console.log(`Invite link: ${inviteLink}`);
      window.open(inviteLink, "_blank");
      return;
    }

    if (!selectedTenant.inContactList) {
      console.log("Contact not in the user's contact list");
      return;
    }

    const message =
      "Hello You have pending rent to pay. This is a reminder message";

    const phoneNumber = `+91${selectedTenant.mobile}`;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, "_blank");
  };

  const divStyle = {
    height: "1.5rem", // h-6
    width: "6rem", // w-20
    borderRadius: "9999px", // rounded-full
    backgroundColor: "transparent", // bg-red-500
    borderWidth: "1px", // border-solid
    borderColor: "blue", // border-solid
    borderStyle: "solid", // border-solid
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    cursor: "pointer",
    color: "blue", // Updated text color to blue
  };

  return (
    <div style={divStyle}>
      <FaWhatsapp size={15} />
      <button onClick={sendMessageToWhatsApp}>
        {label}
      </button>
    </div>
  );
};

export default WhatsAppComponent;
