import React from 'react';

const ImageModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white rounded-lg max-w-lg w-1/2 max-h-[90vh] overflow-y-auto p-10">
        <button className="absolute top-2 right-2 text-black" onClick={onClose}>Close</button>
        {children}
      </div>
    </div>
  );
};

export default ImageModal;
