import React from "react";
import "./rentedbtn.css";

function RentedBtn({children,className}) {
  return (
    <div>
      <button className={`rented-btn ${className}`}>
        {children}
      </button>
    </div>
  );
}

export default RentedBtn;
