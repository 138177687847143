import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const TrendGraph = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current;

    if (ctx) {
      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN'],
          datasets: [{
            // label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });

      return () => {
        myChart.destroy(); // Clean up chart instance on component unmount
      };
    }
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  return (
    <canvas ref={chartRef} style={{ background: 'transparent' }} id="myChart"></canvas>
  );
}

export default TrendGraph;
