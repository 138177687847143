import React, { useEffect, useState } from "react";
import "./tenants.css";
import { Avatar } from "@mui/material";
import PhoneIcon from "../../assets/DashboardIcons/Phone.svg";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import CircularInitial from "../UpcomingRent/CircularInitial";

function Tenants() {
  const { jwt } = useAuth();
  const [data, setTenantData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall("GET", paths.tenant.fetchAll, undefined, undefined, jwt);
        console.log("Response:", response);
        setTenantData(response.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [jwt]);

  return (
    <div className="tenants-container p-4">
      {/* Render data if available */}
      {data && data.slice(0, 4).map((item, index) => (
        <div key={index} className="flex justify-between items-center pb-2">
          <div className="flex items-center gap-2">
            <CircularInitial firstName={item.first_name} lastName={item.last_name}/>
            <div>
              <p>{item.first_name}</p>
              <p>{item.mobile}</p>
            </div>
          </div>
          <div style={{ border: 1, borderRadius: 99, borderStyle: 'solid', padding: 10 }}>
            <img src={PhoneIcon} alt="Phone" height={15} width={15} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Tenants;
