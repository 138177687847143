import React from "react";
import './properties.css'
import Property from "./Property";


function Properties() {
  return (
    <div className="properties-container">
      <Property />
    </div>
  );
}

export default Properties;
