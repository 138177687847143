import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout'; // Adjust the import path as necessary
import LoginForm from './LoginForm';
import OTP from './OTP';
import AddInfo from './AddInfo';
import Dashboard from './Dashboard';
import { useAuth } from './Utility/AuthProvider';
import ViewAll from './components/UpcomingRent/ViewAll';
import MyProperties from './components/Properties/MyProperties';
import Reports from './components/Reports/Reports';
import Payments from './components/PaymentsTab/Payments';
import WhatsAppComponent from './components/Reports/components/WhatsappComponent';
import Units from './components/Properties/Units';
import Buildings from './components/Properties/Buildings';
import AddNewBuilding from './components/Properties/AddNewBuilding';
import AddNewUnit from './components/Properties/AddNewUnit';
import UnitDetails from './components/Properties/UnitDetails';
import BuildingDetails from './components/Properties/BuildingDetails';
import TenantsList from './components/Properties/TenantsList';
import AddNewTenant from './components/Tenants/AddNewTenant';

function App() {
  const { jwt } = useAuth();

  return (
    <div className="App">
      <Router>
        <Routes>
          {jwt !== "" ? (
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="ViewAll" element={<ViewAll />} />
              <Route path="Payments" element={<Payments />} />
              <Route path="AddInfo" element={<AddInfo />} />
              <Route path="MyProperties" element={<MyProperties />} />
              <Route path="Reports" element={<Reports />} />
              <Route path="Units" element={<Units />} />
              <Route path="Buildings" element={<Buildings />} />
              <Route path="AddNewBuilding" element={<AddNewBuilding />} />
              <Route path="AddNewUnit" element={<AddNewUnit />} />
              <Route path="AddNewTenant" element={<AddNewTenant/>}/>
              <Route path="MyProperties/:unitId" element={<UnitDetails />} />
              <Route path="MyProperties/BuildingDetails/:buildingId" element={<BuildingDetails />} />
              <Route path="MyProperties/TenantsList" element={<TenantsList />} />
              <Route path="WhatsAppComponent" element={<WhatsAppComponent />} />
            </Route>
          ) : (
            <>
              <Route path="/" element={<LoginForm />} />
              <Route path="/OTP" element={<OTP />} />
              <Route path="/AddInfo" element={<AddInfo />} />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
