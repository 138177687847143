import React from "react";
import "./payments.css";
import RecentPayments from "../RecentPayments/RecentPayments";

function Payments() {
  return (
    <div
      className="container"
      style={{
        height: "calc(100vh)",
      }}
    >
      <p className="text-xl">Payments</p>

      <div className="sub-container-payments-row2">
        <div>
          <RecentPayments />
        </div>
      </div>
    </div>
  );
}

export default Payments;
