import React from 'react'
import TrendGraph from './TrendGraph'


function Trends() {

  const numericalData = [10, 20, 30, 40, 50, 60]; // Example data for 6 months

  return (
    <div className='' style={
        {
            // height:235,
            // width:350,
            backgroundColor:'white',
            borderRadius:20,
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            padding:24
        }
    }>
        <p>Monthly average: ₹24,55,500.00</p>
        <div className='flex justify-between'>
      <TrendGraph/>
        </div>
    </div>
  )
}

export default Trends