import React, { useEffect, useState } from "react";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import { IoIosAdd } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import ToggleBtn from "../../components/Buttons/ToggleBtn";
import FilterBtn from "../../components/Buttons/FilterBtn";
import TenantStatusBtn from "../../components/Buttons/TenantStatusBtn";
import CircularInitial from "../UpcomingRent/CircularInitial";
import { CiCircleInfo } from "react-icons/ci";
import { CiWallet } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function TenantsList() {
  const { jwt } = useAuth();
  const [tenantData, setTenantData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall(
          "GET",
          paths.tenant.fetchAll,
          undefined,
          undefined,
          jwt
        );
        console.log("Response of tenant fetchAll call:", response);
        setTenantData(response.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [jwt]);

  useEffect(() => {
    const applyFilters = () => {
      let data = tenantData;
  
      // Apply search filter
      if (searchQuery) {
        data = data.filter(tenant =>
          tenant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tenant.mobile.includes(searchQuery) ||
          tenant.unit_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
  
      // Apply selected filters
      if (selectedFilters.length > 0) {
        data = data.filter(tenant => {
          // Check if the tenant's status matches any selected filter
          if (selectedFilters.includes(tenant.status)) {
            return true;
          }
          
          // Check "Due" filter
          if (selectedFilters.includes("Due") && tenant.due_date) {
            const dueInDays = parseInt(tenant.due_date, 10);
            if (dueInDays >= 0 && dueInDays <= 14) {
              return true;
            }
          }
  
          // Check "Paid" filter
          if (selectedFilters.includes("Paid") && tenant.due_date) {
            const dueInDays = parseInt(tenant.due_date, 10);
            if (dueInDays >= 23 && dueInDays <= 31) {
              return true;
            }
          }
  
          // Default to exclude if no match
          return false;
        });
      }
  
      setFilteredData(data);
    };
  
    applyFilters();
  }, [tenantData, selectedFilters, searchQuery]);
  

  const toggleFilter = (filter) => {
    setSelectedFilters((prevSelected) =>
      prevSelected.includes(filter)
        ? prevSelected.filter((item) => item !== filter)
        : [...prevSelected, filter]
    );
  };

  const getStatusProps = (due_date) => {
    if (due_date === "Overdue") {
      return {
        label: "Overdue",
        className: "text-red border border-red-500",
        icon: <CiCircleInfo size={15} color="red"/>,
      };
    }
    const dueInDays = parseInt(due_date, 10);
    if (!isNaN(dueInDays)) {
      if (dueInDays >= 0 && dueInDays <= 22) {
        return {
          label: `Due in ${dueInDays} days`,
          className: "text-orange-500 border border-orange-500",
          icon: <CiCircleInfo size={15} color="orange"/>,
        };
      } else if (dueInDays >= 23 && dueInDays <= 31) {
        return {
          label: "Paid",
          className: "text-blue-700 border border-blue-700",
          icon: <CiWallet size={15} color="blue"/>,
        };
      }
    }
    return { label: "Unknown", className: "bg-gray-500 text-white" };
  };


  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  function navigateAddTenant(){
    navigate("/AddNewTenant")
  }

  const filters = ["Active", "Paid", "Due", "Overdue", "Inactive"];

  return (
    <div className="p-10 border-2 rounded-2xl">
      <div className="flex items-center justify-between">
        <p className="font-black">My Tenant</p>
        <button onClick={navigateAddTenant} className="flex items-center bg-blue-700 text-white text-xs p-3 rounded-full">
          <IoIosAdd size={15} />
          Add New
        </button>
      </div>
      <div className="flex items-center border-gray border mt-4 p-2 rounded-full gap-2">
        <CiSearch size={20} />
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="flex-grow outline-none bg-transparent"
        />
      </div>

      <div className="tenant-filter-container mt-5">
        <div className="flex flex-wrap gap-2">
          <FilterBtn selectedFiltersCount={selectedFilters.length} onClick={handleClearFilters}/>

          {filters.map((filter) => (
            <ToggleBtn
              key={filter}
              label={filter}
              isSelected={selectedFilters.includes(filter)}
              onClick={() => toggleFilter(filter)}
            />
          ))}
        </div>
      </div>

      <div className="mt-5 border rounded-2xl">
        <table className="w-full">
          <thead className="text-sm">
            <tr>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Mobile Number</th>
              <th className="px-3 py-2 text-left">Unit Address</th>
              <th className="px-4 py-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((tenant, index) => (
                <tr className="text-xs" key={index}>
                  <td className="px-4 py-2 border-b flex items-center gap-4">
                    <div className=" text-white rounded-full h-8 w-8 flex items-center justify-center text-xs">
                      <CircularInitial
                        firstName={tenant.first_name}
                        lastName={tenant.last_name}
                      />
                    </div>
                    <p className="text-black">{tenant.name}</p>
                  </td>
                  <td className="px-4 py-2 border-b">{tenant.mobile}</td>
                  <td className="px-4 py-2 border-b">{tenant.unit_name}</td>
                  <td className="px-4 py-2 border-b">
                    <TenantStatusBtn {...getStatusProps(tenant.due_date)} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="px-4 py-2 text-gray-600 text-center">
                  No tenants found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TenantsList;
