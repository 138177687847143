import React from "react";

const CircularInitial = ({ firstName, lastName }) => {
  // Function to generate random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Ensure firstName and lastName are strings and not undefined or null
  const initialFirst = firstName ? firstName.charAt(0).toUpperCase() : '';
  const initialLast = lastName ? lastName.charAt(0).toUpperCase() : '';

  return (
    <div
      style={{
        height: "30px",
        width: "30px",
        backgroundColor: getRandomColor(),
        borderRadius: "50%",
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: '10px'
      }}
    >
      <p className="text-center p-4" style={{ margin: 0 }}>
        {`${initialFirst}${initialLast}`}
      </p>
    </div>
  );
};

export default CircularInitial;
