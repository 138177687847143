import React, { createContext, useContext, useEffect, useState } from 'react';


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [jwt, setJwt] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem('jwtToken')
    if(storedToken) {
      setJwt(storedToken)
    }
  }, [])

  const storeJwt = (jwt) => {
   
    // Update authentication status upon successful login
    setJwt(jwt);
    localStorage.setItem('jwtToken', jwt)
  };

  const deleteJwt = () => {
    // Update authentication status upon logout
    //Work on encrypting(!static key - avoid the plublic access of this jwt) the localStorage to protect jwt.......
    setJwt("");
    localStorage.removeItem('jwtToken')
  };

  return (
    <AuthContext.Provider value={{ jwt, storeJwt, deleteJwt }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
